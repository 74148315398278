import { Container } from '@mui/material';
import { useLocation } from 'react-router-dom';

import './styles/variables.css';

import { AuthenticatedRoutes, ActivateRoutes, LoginRoutes } from './Routes';

import { useLiveChat } from '@/helpers/liveChat';
import { useRollbar } from '@/helpers/rollbar';

const App = () => {
  const location = useLocation();
  useRollbar();
  useLiveChat();

  // eslint-disable-next-line no-undef
  const authStrategy = process.env.VITE_AUTH_STRATEGY;

  const isActivateRoutes = location.pathname.startsWith('/users/activate');
  const isLoginRoute = location.pathname.startsWith('/login') && authStrategy === 'zebel-api';

  const renderRoutes = () => {
    if (isActivateRoutes) {
      return <ActivateRoutes />;
    } else if (isLoginRoute) {
      return <LoginRoutes />;
    } else {
      return <AuthenticatedRoutes />;
    }
  };

  return (
    <Container disableGutters={true} maxWidth={false}>
      {renderRoutes()}
    </Container>
  );
};

export default App;
