export const formatNumber = (value = '', minDecimalPlaces = 0, maxDecimalPlaces = minDecimalPlaces) => {
  if (minDecimalPlaces > maxDecimalPlaces) {
    throw Error('Min decimal places must be more than max decimal places');
  }

  return parseNumericValue(value).toLocaleString('en-US', { minimumFractionDigits: minDecimalPlaces, maximumFractionDigits: maxDecimalPlaces });
};

export const safeDivide = (a, b) => {
  return b === 0 ? 0 : a / b;
};

export const applyPercentage = (value, percentage) => {
  const result = parseNumericValue(value) * (1 + parseNumericValue(percentage) / 100);
  // Round to 10 decimal places to avoid floating point precision issues
  return Number(result.toFixed(10));
};

export const parseNumericValue = (value) => {
  if (value === null || value === undefined) return 0;

  // If it's already a number, just return it
  if (typeof value === 'number') return value;

  // Convert to string and remove all characters except digits and decimal point
  const cleanedValue = value.toString().replace(/[^\d.-]/g, '');
  return parseFloat(cleanedValue) || 0;
};
