import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import httpClient from '../../services/httpClient';
import { escalationConfigTemplate, filterConfigTemplate } from '../../pages/compare/helpers';

import { API_STATUS_ENUM } from '@/helpers/enums';
import { calculateEffectiveEscalation } from '@/helpers/escalation';

const defaultApiConfig = {
  getApiStatus: API_STATUS_ENUM.PENDING,
  data: {}
};

const initialState = {
  mainComparingRecordUid: '',
  isAddingRecord: false,
  isPDFExportVisible: false,
  comparingRecords: {},
  layoutTypeChoice: 'create',
  selectedLayout: '',
  selectedDesignMetrics: [],
  selectedCostMetrics: [],
  selectedCostFieldGroups: [],
  selectedCostCategoryGroups: [],
  selectedCostCategories: [],
  selectedCSIDivisions: [],
  selectedTotalCosts: [],
  hoveredRow: null,
  isVarianceVisible: false,
  varianceComparingRecordUids: [],
  escalation: defaultApiConfig,
  compareLayouts: defaultApiConfig
};

export const getEscalation = createAsyncThunk('getEscalation', async () => {
  try {
    const result = await httpClient.getCompanyEscalationRates();
    return result;
  } catch (err) {
    throw Error(err);
  }
});

export const getCompareLayouts = createAsyncThunk('getCompareLayouts', async () => {
  try {
    const result = await httpClient.getCompareLayouts();
    const layoutObject = {};
    result.forEach((layout) => {
      layoutObject[layout.uid] = layout;
    });
    return layoutObject;
  } catch (err) {
    throw Error(err);
  }
});

export const getCompanyRecordFields = createAsyncThunk('getCompanyRecordFields', async ({ recordUid }) => {
  try {
    let designFields = {};
    let costFields = {};
    let costCategories = {};
    let costCategoryGroups = { Other: { uid: 'Other', label: 'Other', selected_uom: '', buckets: [] } };
    let costFieldGroups = { Other: { uid: 'Other', label: 'Other', selected_uom: '', fields: [] } };
    let csiDivisions = {};

    const allBuckets = await httpClient.getRecordBuckets(recordUid);
    allBuckets.forEach((bucket) => {
      if (bucket.metadata) {
        const metadata = JSON.parse(bucket.metadata);
        const group = metadata?.tags?.group;
        if (group) {
          if (costCategoryGroups[group]) {
            costCategoryGroups[group].buckets = [...costCategoryGroups[group].buckets, bucket];
          } else {
            costCategoryGroups[group] = { label: group, uid: group, selected_uom: '', buckets: [bucket] };
          }
        } else {
          costCategoryGroups.Other.buckets.push(bucket);
        }
      } else {
        costCategoryGroups.Other.buckets.push(bucket);
      }
    });

    const allFields = await httpClient.getRecordFields(recordUid, null, null, false, false);
    allFields.forEach((field) => {
      if (field.field_category === 'Design') {
        // Add design fields to design object
        designFields[field.uid] = field;
      }
      if (field.field_category === 'Cost') {
        // Add cost fields to cost object
        costFields[field.uid] = field;

        // Parse out the fields group and add it to the cost field groups object
        if (field.metadata) {
          const metadata = JSON.parse(field.metadata);
          const group = metadata?.tags?.group;
          const bucket = field.bucket;

          if (group) {
            const costFieldGroupName = `${bucket}-${group}`;
            if (costFieldGroups[costFieldGroupName]) {
              costFieldGroups[costFieldGroupName].fields = [...costFieldGroups[costFieldGroupName].fields, field];
            } else {
              let costCodeGroupLabel = '';
              if (costCategoryGroups) {
                const group = Object.keys(costCategoryGroups).find((costCategoryGroup) => {
                  return costCategoryGroups[costCategoryGroup].buckets.find((b) => b.bucket_code === field.bucket_code);
                });
                costCodeGroupLabel = costCategoryGroups[group].label;
              }
              costFieldGroups[costFieldGroupName] = {
                uid: costFieldGroupName,
                selected_uom: '',
                bucket_name: field.bucket_name,
                cost_code_group: costCodeGroupLabel,
                fields: [field],
                label: group
              };
            }
          } else {
            costFieldGroups.Other.fields.push(field);
          }
        } else {
          costFieldGroups.Other.fields.push(field);
        }
      }
    });

    const allCategories = await httpClient.getAllCostBucketsTotalCost(recordUid);
    allCategories.forEach((category) => {
      costCategories[category.bucket] = category;
    });

    const allCSIDivisions = await httpClient.getUnitCostBreakDownData(
      {
        escalation: {
          enabled: false
        },
        productType: {
          enabled: false,
          data: { selected: ['podium', 'wrap', 'wood_podium', 'slab_on_grade', 'high_rise', 'various'] }
        },
        region: {
          enabled: false,
          data: { regions: [] }
        },
        buyoutTime: {
          enabled: false,
          data: { selected: [100] }
        },
        projectStatus: {
          enabled: false,
          data: { selected: ['quick-estimate', 'schematic-design', 'pre-construction', 'construction-documents', 'contract', 'in-construction', 'completed'] }
        },
        rentalType: {
          enabled: false,
          data: { selected: ['market_rate', 'affordable', 'mixed_income'] }
        },
        union: {
          enabled: false,
          data: { selected: [0] }
        },
        unitCount: {
          enabled: false,
          data: { minCount: 0, maxCount: 10000 }
        }
      },
      recordUid,
      '00000000000000000000000000000000'
    );
    allCSIDivisions.forEach((csiDivision) => {
      if (csiDivision.division_code !== null) {
        csiDivisions[csiDivision.division_uid] = csiDivision;
      }
    });

    const [totalCost] = await httpClient.getRecordTotalCostSum(recordUid);

    return { designFields, costFields, costFieldGroups, costCategoryGroups, costCategories, csiDivisions, totalCost };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    throw Error(err);
  }
});

export const getMarketRecordFields = createAsyncThunk('getMarketRecordFields', async () => {
  try {
    let designFields = {};
    const allDesignFields = await httpClient.getStandardFields('multifamily');
    allDesignFields
      .filter((designField) => designField.field_category === 'Design')
      .forEach((designField) => {
        designFields[designField.uid] = designField;
      });

    return { designFields };
  } catch (err) {
    throw Error(err);
  }
});

export const getMarketData = createAsyncThunk('getMarketData', async ({ comparingRecordUid, divisionUid, designFieldUid }, helpers) => {
  try {
    const apiDesignFieldUid = designFieldUid === '-' ? '22539d6e2857419ea6e10927d74de3f5' : designFieldUid;
    const filterConfig = helpers.getState().compare.comparingRecords[comparingRecordUid].filterConfig;
    const [result] = await httpClient.getMarketUnitCostStats(filterConfig, divisionUid, apiDesignFieldUid);
    return result;
  } catch (err) {
    throw Error(err);
  }
});

export const getMarketChartData = createAsyncThunk('getMarketChartData', async ({ comparingRecordUid, divisionUid, designFieldUid }, helpers) => {
  try {
    const filterConfig = helpers.getState().compare.comparingRecords[comparingRecordUid].filterConfig;
    const apiDesignFieldUid = designFieldUid === '-' ? '22539d6e2857419ea6e10927d74de3f5' : designFieldUid;
    const result = await httpClient.getMarketUnitCost(filterConfig, divisionUid, apiDesignFieldUid);
    const marketData = result.filter((r) => r.is_market).map((r) => ({ ...r, is_market: 'Market' }));
    return marketData;
  } catch (err) {
    throw Error(err);
  }
});

export const compareSlice = createSlice({
  name: 'compare',
  initialState,
  reducers: {
    resetCompare: () => initialState,
    setIsAddingRecord: (state, action) => {
      state.isAddingRecord = action.payload;
    },
    setInitialCompareState: (state, action) => {
      state.comparingRecords = action.payload.comparingRecords || {};
      state.layoutTypeChoice = action.payload.layoutTypeChoice || 'create';
      state.selectedLayout = action.payload.selectedLayout || '';
      state.isVarianceVisible = action.payload.isVarianceVisible || false;
      state.varianceComparingRecordUids = action.payload.varianceComparingRecordUids || [];

      // There is some incosistencies in the use of uid, id in older layouts, so normalize them all here to avoid issues in the ui
      state.selectedCostCategoryGroups = (action.payload.selectedCostCategoryGroups || []).map((i) => ({ ...i, uid: uuid() }));
      state.selectedCostFieldGroups = (action.payload.selectedCostFieldGroups || []).map((i) => ({ ...i, uid: uuid() }));
      state.selectedCostCategories = (action.payload.selectedCostCategories || []).map((i) => ({ ...i, uid: uuid() }));
      state.selectedCostMetrics = (action.payload.selectedCostMetrics || []).map((i) => ({ ...i, uid: uuid() }));
      state.selectedDesignMetrics = (action.payload.selectedDesignMetrics || []).map((i) => ({ ...i, uid: uuid() }));
      state.selectedCSIDivisions = (action.payload.selectedCSIDivisions || []).map((i) => ({ ...i, uid: uuid() }));
      state.selectedTotalCosts = (action.payload.selectedTotalCosts || []).map((i) => ({ ...i, uid: uuid() }));

      const comparingRecordUids = Object.keys(action.payload.comparingRecords);
      if (comparingRecordUids.length > 0) {
        state.mainComparingRecordUid = comparingRecordUids[0];
      }
    },
    addComparingRecord: (state, action) => {
      const newComparingRecordUid = uuid();

      if (action.payload.type === 'market') {
        state.comparingRecords[newComparingRecordUid] = {
          type: 'market',
          escalationConfig: null,
          filterConfig: filterConfigTemplate,
          fields: defaultApiConfig,
          marketData: {},
          marketChartData: {}
        };
      } else {
        state.comparingRecords[newComparingRecordUid] = {
          type: 'company',
          escalationConfig: escalationConfigTemplate,
          projectUid: action.payload.projectUid,
          recordUid: action.payload.recordUid,
          fields: defaultApiConfig,
          marketData: {},
          marketChartData: {}
        };
      }

      if (!state.mainComparingRecordUid) {
        state.mainComparingRecordUid = newComparingRecordUid;
      }

      state.isAddingRecord = false;
    },
    deleteComparingRecord: (state, action) => {
      if (state.varianceComparingRecordUids.includes(action.payload.comparingRecordUid)) {
        state.isVarianceVisible = false;
        state.varianceComparingRecordUids = [];
      }

      delete state.comparingRecords[action.payload.comparingRecordUid];
    },
    setComparingRecord: (state, action) => {
      if (action.payload.type === 'market') {
        state.comparingRecords[action.payload.comparingRecordUid].type = 'market';
        state.comparingRecords[action.payload.comparingRecordUid].fields = defaultApiConfig;
        state.comparingRecords[action.payload.comparingRecordUid].filterConfig = filterConfigTemplate;
        state.comparingRecords[action.payload.comparingRecordUid].marketData = {};
        state.comparingRecords[action.payload.comparingRecordUid].marketChartData = {};
      } else {
        state.comparingRecords[action.payload.comparingRecordUid].projectUid = action.payload.projectUid;
        state.comparingRecords[action.payload.comparingRecordUid].recordUid = action.payload.recordUid;
        state.comparingRecords[action.payload.comparingRecordUid].type = 'company';
        state.comparingRecords[action.payload.comparingRecordUid].fields = defaultApiConfig;
        state.comparingRecords[action.payload.comparingRecordUid].escalationConfig = escalationConfigTemplate;
        state.comparingRecords[action.payload.comparingRecordUid].marketData = {};
        state.comparingRecords[action.payload.comparingRecordUid].marketChartData = {};
        state.comparingRecords[action.payload.comparingRecordUid].isMsaPremium = false;
      }
    },
    setComparingRecordType: (state, action) => {
      // If its being used in the variance, reset the variance to initial values
      if (state.varianceComparingRecordUids.includes(action.payload.comparingRecordUid)) {
        state.isVarianceVisible = false;
        state.varianceComparingRecordUids = [];
      }

      state.comparingRecords[action.payload.comparingRecordUid].type = action.payload.type;
      state.comparingRecords[action.payload.comparingRecordUid].escalationConfig = escalationConfigTemplate;
      state.comparingRecords[action.payload.comparingRecordUid].fields = defaultApiConfig;
      state.comparingRecords[action.payload.comparingRecordUid].filterConfig = filterConfigTemplate;
      state.comparingRecords[action.payload.comparingRecordUid].marketData = {};
      state.comparingRecords[action.payload.comparingRecordUid].marketChartData = {};
    },
    setComparingRecordEscalationValue: (state, action) => {
      state.comparingRecords[action.payload.comparingRecordUid].escalationConfig[action.payload.key] = action.payload.value;

      if (state.comparingRecords[action.payload.comparingRecordUid].escalationConfig.isEscalated) {
        state.comparingRecords[action.payload.comparingRecordUid].escalationConfig.effectiveEscalation = calculateEffectiveEscalation(
          { ...state.escalation.data },
          action.payload.buyoutDate.slice(0, 10),
          state.comparingRecords[action.payload.comparingRecordUid].escalationConfig.toDate
        );
      } else {
        state.comparingRecords[action.payload.comparingRecordUid].escalationConfig.effectiveEscalation = 0;
      }
    },
    setComparingRecordMsaPremium: (state, action) => {
      state.comparingRecords[action.payload.comparingRecordUid].isMsaPremium = action.payload.isMsaPremium;
      state.comparingRecords[action.payload.comparingRecordUid].fromMsaPremium = action.payload.fromMsaPremium;
      state.comparingRecords[action.payload.comparingRecordUid].fromMsaPremiumUid = action.payload.fromMsaPremiumUid;
      state.comparingRecords[action.payload.comparingRecordUid].toMsaPremium = action.payload.toMsaPremium;
      state.comparingRecords[action.payload.comparingRecordUid].toMsaPremiumUid = action.payload.toMsaPremiumUid;
      state.comparingRecords[action.payload.comparingRecordUid].effectiveRegionalAdjustment = action.payload.effectiveRegionalAdjustment;
    },
    setAllComparingRecordsToMsaPremium: (state, action) => {
      const comparingRecordUids = Object.keys(state.comparingRecords);
      comparingRecordUids.forEach((comparingRecordUid) => {
        state.comparingRecords[comparingRecordUid].toMsaPremium = action.payload.toMsaPremium;
        state.comparingRecords[comparingRecordUid].toMsaPremiumUid = action.payload.toMsaPremiumUid;
        state.comparingRecords[comparingRecordUid].effectiveRegionalAdjustment = action.payload.effectiveRegionalAdjustment;
      });
    },
    incrementComparingRecordFilterUpdates: (state, action) => {
      if (!state.comparingRecords[action.payload.comparingRecordUid].filterUpdate) {
        state.comparingRecords[action.payload.comparingRecordUid].filterUpdate = 1;
      } else {
        state.comparingRecords[action.payload.comparingRecordUid].filterUpdate += 1;
      }
      // reset the market charts so they fetch new data when rendered again
      state.comparingRecords[action.payload.comparingRecordUid].marketChartData = {};
    },
    setVisibleParameter: (state, action) => {
      state.visibleParameters[action.payload.key] = action.payload.value;
    },
    setLayoutTypeChoice: (state, action) => {
      state.layoutTypeChoice = action.payload;
    },
    setSelectedLayout: (state, action) => {
      state.selectedLayout = action.payload;
    },
    setSelectedLayoutValues: (state, action) => {
      state.selectedLayout = action.payload.layout.uid;
      state.layoutTypeChoice = 'useExisting';
      // There is some incosistencies in the use of uid, id in older layouts, so normalize them all here to avoid issues in the ui
      state.selectedCostCategoryGroups = (action.payload.layout.items.costCodeGroups || []).map((i) => ({ ...i, uid: uuid() }));
      state.selectedCostFieldGroups = (action.payload.layout.items.costFieldGroups || []).map((i) => ({ ...i, uid: uuid() }));
      state.selectedCostCategories = (action.payload.layout.items.buckets || []).map((i) => ({ ...i, uid: uuid() }));
      state.selectedCostMetrics = (action.payload.layout.items.cost || []).map((i) => ({ ...i, uid: uuid() }));
      state.selectedDesignMetrics = (action.payload.layout.items.design || []).map((i) => ({ ...i, uid: uuid() }));
      state.selectedCSIDivisions = (action.payload.layout.items.division || []).map((i) => ({ ...i, uid: uuid() }));
      // .map((i) => {
      //   // Some older layouts did not save the division uid in the config, and now we need it for the market data
      //   let divisionUid = i.division_uid;
      //   if (!divisionUid) {
      //     const allDivisions = { ...state.divisions.data };
      //     divisionUid = Object.keys(allDivisions).find((duid) => {
      //       const division = { ...allDivisions[duid] };
      //       return division.division_code === i.division_code;
      //     });
      //   }
      //   return { ...i, division_uid: divisionUid, uid: uuid() };
      // });
      state.selectedTotalCosts = (action.payload.layout.items.total || []).map((i) => ({ ...i, uid: uuid() }));
    },
    setCompareLayout: (state, action) => {
      state.compareLayouts.data[action.payload.compareLayoutUid][action.payload.key] = action.payload.value;
    },
    deleteCompareLayout: (state, action) => {
      if (state.selectedLayout === action.payload.compareLayoutUid) {
        state.selectedLayout = '';
        state.layoutTypeChoice = 'create';
      }
      delete state.compareLayouts.data[action.payload.compareLayoutUid];
    },
    addCompareParameter: (state, action) => {
      state[action.payload.parameterType].push(action.payload.parameter);
    },
    deleteCompareParameter: (state, action) => {
      state[action.payload.parameterType] = state[action.payload.parameterType].filter((parameter) => parameter.uid !== action.payload.uid);
    },
    setCompareParameter: (state, action) => {
      const index = state[action.payload.parameterType].findIndex((parameter) => parameter.uid === action.payload.parameter.uid);
      state[action.payload.parameterType][index] = action.payload.parameter;
    },
    reorderCompareParameter: (state, action) => {
      const results = state[action.payload.parameter].slice();
      const firstItem = state[action.payload.parameter][action.payload.oldIndex];
      results[action.payload.oldIndex] = state[action.payload.parameter][action.payload.newIndex];
      results[action.payload.newIndex] = firstItem;
      state[action.payload.parameter] = results;
    },
    setHoveredRow: (state, action) => {
      state.hoveredRow = action.payload;
    },
    setIsPDFExportVisible: (state, action) => {
      state.isPDFExportVisible = action.payload;
    },
    setIsVarianceVisible: (state, action) => {
      const currentComparingRecords = Object.keys(state.comparingRecords);
      if (action.payload === true && currentComparingRecords.length > 1) {
        state.varianceComparingRecordUids = [currentComparingRecords[0], currentComparingRecords[1]];
      }
      state.isVarianceVisible = action.payload;
    },
    setVarianceComparingRecordUids: (state, action) => {
      state.varianceComparingRecordUids = action.payload;
    },
    setComparingRecordMarketFilter: (state, action) => {
      state.comparingRecords[action.payload.comparingRecordUid].filterConfig = action.payload.value;
    },
    clearComparingRecordMarketFilter: (state, action) => {
      state.comparingRecords[action.payload.comparingRecordUid].filterConfig[action.payload.filter].data = filterConfigTemplate[action.payload.filter].data;
      state.comparingRecords[action.payload.comparingRecordUid].filterConfig[action.payload.filter].enabled = false;
    },
    enableComparingRecordMarketFilter: (state, action) => {
      state.comparingRecords[action.payload.comparingRecordUid].filterConfig[action.payload.filter].enabled = true;
    }
  },
  extraReducers: (builder) => {
    // GET ESCALATION //
    // PENDING
    builder.addCase(getEscalation.pending, (state) => {
      state.escalation.getApiStatus = API_STATUS_ENUM.PENDING;
    });
    // REJECTED
    builder.addCase(getEscalation.rejected, (state) => {
      state.escalation.getApiStatus = API_STATUS_ENUM.ERROR;
    });
    // SUCCESS
    builder.addCase(getEscalation.fulfilled, (state, action) => {
      state.escalation.getApiStatus = API_STATUS_ENUM.SUCCESS;
      state.escalation.data = action.payload;
    });
    // GET COMPARE LAYOUTS //
    // PENDING
    builder.addCase(getCompareLayouts.pending, (state) => {
      state.compareLayouts.getApiStatus = API_STATUS_ENUM.PENDING;
    });
    // REJECTED
    builder.addCase(getCompareLayouts.rejected, (state) => {
      state.compareLayouts.getApiStatus = API_STATUS_ENUM.ERROR;
    });
    // SUCCESS
    builder.addCase(getCompareLayouts.fulfilled, (state, action) => {
      state.compareLayouts.getApiStatus = API_STATUS_ENUM.SUCCESS;
      state.compareLayouts.data = action.payload;
    });
    // GET COMPANY RECORD FIELDS //
    // PENDING
    builder.addCase(getCompanyRecordFields.pending, (state, action) => {
      state.comparingRecords[action.meta.arg.comparingRecordUid].fields.getApiStatus = API_STATUS_ENUM.PENDING;
    });
    // REJECTED
    builder.addCase(getCompanyRecordFields.rejected, (state, action) => {
      if (state.comparingRecords[action.meta.arg.comparingRecordUid]) {
        state.comparingRecords[action.meta.arg.comparingRecordUid].fields.getApiStatus = API_STATUS_ENUM.ERROR;
      }
    });
    // SUCCESS
    builder.addCase(getCompanyRecordFields.fulfilled, (state, action) => {
      if (state.comparingRecords[action.meta.arg.comparingRecordUid]) {
        state.comparingRecords[action.meta.arg.comparingRecordUid].fields.getApiStatus = API_STATUS_ENUM.SUCCESS;
        state.comparingRecords[action.meta.arg.comparingRecordUid].fields.data = action.payload;
      }
    });
    // GET MARKET FIELDS //
    // PENDING
    builder.addCase(getMarketRecordFields.pending, (state, action) => {
      state.comparingRecords[action.meta.arg.comparingRecordUid].fields.getApiStatus = API_STATUS_ENUM.PENDING;
    });
    // REJECTED
    builder.addCase(getMarketRecordFields.rejected, (state, action) => {
      state.comparingRecords[action.meta.arg.comparingRecordUid].fields.getApiStatus = API_STATUS_ENUM.ERROR;
    });
    // SUCCESS
    builder.addCase(getMarketRecordFields.fulfilled, (state, action) => {
      state.comparingRecords[action.meta.arg.comparingRecordUid].fields.getApiStatus = API_STATUS_ENUM.SUCCESS;
      state.comparingRecords[action.meta.arg.comparingRecordUid].fields.data = action.payload;
    });
    // GET MARKET DATA //
    // PENDING
    builder.addCase(getMarketData.pending, (state, action) => {
      const divisionUid = action.meta.arg.divisionUid;
      const comparingRecordUid = action.meta.arg.comparingRecordUid;
      const designFieldUid = action.meta.arg.designFieldUid;
      if (!state.comparingRecords[comparingRecordUid].marketData[divisionUid]) {
        state.comparingRecords[comparingRecordUid].marketData[divisionUid] = {};
      }
      if (!state.comparingRecords[comparingRecordUid].marketData[divisionUid][designFieldUid]) {
        state.comparingRecords[comparingRecordUid].marketData[divisionUid][designFieldUid] = { ...defaultApiConfig };
      }

      state.comparingRecords[comparingRecordUid].marketData[divisionUid][designFieldUid].getApiStatus = API_STATUS_ENUM.PENDING;
    });
    // REJECTED
    builder.addCase(getMarketData.rejected, (state, action) => {
      const divisionUid = action.meta.arg.divisionUid;
      const comparingRecordUid = action.meta.arg.comparingRecordUid;
      const designFieldUid = action.meta.arg.designFieldUid;
      if (!state.comparingRecords[comparingRecordUid].marketData[divisionUid]) {
        state.comparingRecords[comparingRecordUid].marketData[divisionUid] = {};
      }
      if (!state.comparingRecords[comparingRecordUid].marketData[divisionUid][designFieldUid]) {
        state.comparingRecords[comparingRecordUid].marketData[divisionUid][designFieldUid] = { ...defaultApiConfig };
      }
      state.comparingRecords[comparingRecordUid].marketData[divisionUid][designFieldUid].getApiStatus = API_STATUS_ENUM.ERROR;
    });
    // SUCCESS
    builder.addCase(getMarketData.fulfilled, (state, action) => {
      const divisionUid = action.meta.arg.divisionUid;
      const comparingRecordUid = action.meta.arg.comparingRecordUid;
      const designFieldUid = action.meta.arg.designFieldUid;
      if (!state.comparingRecords[comparingRecordUid].marketData[divisionUid]) {
        state.comparingRecords[comparingRecordUid].marketData[divisionUid] = {};
      }
      if (!state.comparingRecords[comparingRecordUid].marketData[divisionUid][designFieldUid]) {
        state.comparingRecords[comparingRecordUid].marketData[divisionUid][designFieldUid] = { ...defaultApiConfig };
      }
      state.comparingRecords[comparingRecordUid].marketData[divisionUid][designFieldUid].getApiStatus = API_STATUS_ENUM.SUCCESS;
      state.comparingRecords[comparingRecordUid].marketData[divisionUid][designFieldUid].data = action.payload;
    });
    // GET MARKET CHART DATA //
    // PENDING
    builder.addCase(getMarketChartData.pending, (state, action) => {
      const divisionUid = action.meta.arg.divisionUid;
      const comparingRecordUid = action.meta.arg.comparingRecordUid;
      const designFieldUid = action.meta.arg.designFieldUid;
      if (!state.comparingRecords[comparingRecordUid].marketChartData[divisionUid]) {
        state.comparingRecords[comparingRecordUid].marketChartData[divisionUid] = {};
      }
      if (!state.comparingRecords[comparingRecordUid].marketChartData[divisionUid][designFieldUid]) {
        state.comparingRecords[comparingRecordUid].marketChartData[divisionUid][designFieldUid] = { ...defaultApiConfig };
      }

      state.comparingRecords[comparingRecordUid].marketChartData[divisionUid][designFieldUid].getApiStatus = API_STATUS_ENUM.PENDING;
    });
    // REJECTED
    builder.addCase(getMarketChartData.rejected, (state, action) => {
      const divisionUid = action.meta.arg.divisionUid;
      const comparingRecordUid = action.meta.arg.comparingRecordUid;
      const designFieldUid = action.meta.arg.designFieldUid;
      if (!state.comparingRecords[comparingRecordUid].marketChartData[divisionUid]) {
        state.comparingRecords[comparingRecordUid].marketChartData[divisionUid] = {};
      }
      if (!state.comparingRecords[comparingRecordUid].marketChartData[divisionUid][designFieldUid]) {
        state.comparingRecords[comparingRecordUid].marketChartData[divisionUid][designFieldUid] = { ...defaultApiConfig };
      }
      state.comparingRecords[comparingRecordUid].marketChartData[divisionUid][designFieldUid].getApiStatus = API_STATUS_ENUM.ERROR;
    });
    // SUCCESS
    builder.addCase(getMarketChartData.fulfilled, (state, action) => {
      const divisionUid = action.meta.arg.divisionUid;
      const comparingRecordUid = action.meta.arg.comparingRecordUid;
      const designFieldUid = action.meta.arg.designFieldUid;
      if (!state.comparingRecords[comparingRecordUid].marketChartData[divisionUid]) {
        state.comparingRecords[comparingRecordUid].marketChartData[divisionUid] = {};
      }
      if (!state.comparingRecords[comparingRecordUid].marketChartData[divisionUid][designFieldUid]) {
        state.comparingRecords[comparingRecordUid].marketChartData[divisionUid][designFieldUid] = { ...defaultApiConfig };
      }
      state.comparingRecords[comparingRecordUid].marketChartData[divisionUid][designFieldUid].getApiStatus = API_STATUS_ENUM.SUCCESS;
      state.comparingRecords[comparingRecordUid].marketChartData[divisionUid][designFieldUid].data = action.payload;
    });
  }
});

export const {
  resetCompare,
  setInitialCompareState,
  addComparingRecord,
  setComparingRecord,
  setComparingRecordType,
  deleteComparingRecord,
  setComparingRecordEscalationValue,
  incrementComparingRecordFilterUpdates,
  setIsAddingRecord,
  setVisibleParameter,
  setLayoutTypeChoice,
  setSelectedLayout,
  setSelectedLayoutValues,
  setCompareLayout,
  deleteCompareLayout,
  addCompareParameter,
  deleteCompareParameter,
  setCompareParameter,
  reorderCompareParameter,
  setHoveredRow,
  setIsPDFExportVisible,
  setIsVarianceVisible,
  setVarianceComparingRecordUids,
  setComparingRecordMarketFilter,
  clearComparingRecordMarketFilter,
  enableComparingRecordMarketFilter,
  setComparingRecordMsaPremium,
  setAllComparingRecordsToMsaPremium
} = compareSlice.actions;

export default compareSlice.reducer;
