import axios from 'axios';

import { getApiUrl } from '@/helpers/api';

const baseUrl = getApiUrl();

export const getProjects = async () => {
  const url = `${baseUrl}/projects`;

  try {
    const result = await axios.get(url, { withCredentials: true });
    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    return null;
  }
};

export const updateRecordParameter = async (project_uid, record_uid, parameter, value) => {
  try {
    if (!record_uid || !parameter) return null;

    // NOTE) Make sure this list is updated in the Backend as well, if you are modifying this here.
    const valid_updatable_params = [
      'product_type',
      'unit_count',
      'record_status',
      'nrsf',
      'construction_type',
      'rental_type',
      'union_status',
      'record_description',
      'record_name',
      'record_scope',
      'buyout_date',
      'record_date',
      'record_version',
      'ancestor_record_uids',
      'record_template_uid'
    ];

    if (!valid_updatable_params.includes(parameter)) return null;

    const url = `${baseUrl}/projects/${project_uid}/records/${record_uid}/parameter`;
    const result = await axios.put(url, { param_name: parameter, param_value: value });
    return result.data;
  } catch (error) {
    throw Error(error);
  }
};

export const deleteProjectRecord = async (record_uid) => {
  try {
    const url = `${baseUrl}/records/${record_uid}`;
    const result = await axios.delete(url);
    return result;
  } catch (error) {
    throw Error(error);
  }
};

export const createBucket = async (recordUid, formData) => {
  try {
    const url = `${baseUrl}/records/${recordUid}/buckets`;
    const result = await axios.post(url, formData);
    return result.data;
  } catch (err) {
    throw Error(err);
  }
};

export const createBuckets = async (recordUid, bucketsList) => {
  const url = `${baseUrl}/records/${recordUid}/buckets/buckets_bulk`;

  try {
    const result = await axios.post(url, bucketsList);
    if (result.status === 201) return result;
    else return null;
  } catch (error) {
    return error.response;
  }
};

export const deleteAllBuckets = async (record_uid) => {
  const url = `${baseUrl}/records/${record_uid}/buckets/delete/all`;

  try {
    const result = await axios.delete(url);
    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    return null;
  }
};

export const getCompareLayouts = async () => {
  try {
    const url = `${baseUrl}/compare/layouts`;

    const result = await axios.get(url);

    if (result.status === 200) return result.data;
    else return null;
  } catch (err) {
    throw Error(err);
  }
};

export const getCompareLayoutById = async (id) => {
  const url = `${baseUrl}/compare/layouts/${id}`;

  const result = await axios.get(url);

  if (result.status === 200) return result.data;
  else return null;
};

export const createNewLayout = async (data) => {
  const url = `${baseUrl}/compare/layouts`;

  try {
    const result = await axios.post(url, data);
    if (result.status === 200) return result;
    else return null;
  } catch (error) {
    return error.response;
  }
};

export const deleteLayout = async (layoutId) => {
  const url = `${baseUrl}/compare/layouts/${layoutId}`;

  try {
    const result = await axios.delete(url);
    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    return null;
  }
};

export const renameCompareLayout = async (layoutId, newName) => {
  const url = `${baseUrl}/compare/layouts/${layoutId}/name`;

  try {
    const result = await axios.put(url, { new_layout_name: newName });
    if (result.status === 200) return result;
    else return null;
  } catch (error) {
    return error.response;
  }
};

export const getRecordFields = async (record_uid, category, bucketUid, valuesOnly, accumulatedByCostCode) => {
  try {
    let url = `${baseUrl}/records/${record_uid}/fields?`;
    url += category !== null ? `category=${category}&` : '';
    url += bucketUid !== null ? `bucketUid=${bucketUid}&` : '';
    url += valuesOnly !== null ? `valuesOnly=${valuesOnly}&` : '';
    url += accumulatedByCostCode !== null ? `accumulated=${accumulatedByCostCode}` : '';

    const result = await axios.get(url);

    if (result.status === 200) return result.data;
    else return null;
  } catch (err) {
    throw Error(err);
  }
};

export const getRecordBuckets = async (record_uid) => {
  const url = `${baseUrl}/records/${record_uid}/buckets`;

  const result = await axios.get(url);

  if (result.status === 200) return result.data;
  else return null;
};

export const createRecordFieldsBulk = async (fields_data, record_uid) => {
  const url = `${baseUrl}/records/${record_uid}/fields/bulk`;

  // console.log("Creating records bulk fields for", fields_data, record_uid);
  try {
    const result = await axios.post(url, fields_data);
    if (result.status === 201) return result.data;
    else return null;
  } catch (error) {
    return null;
  }
};

export const createRecordFieldsSuperBulk = async (fields_data, record_uid) => {
  const url = `${baseUrl}/records/${record_uid}/fields/bulk/super`;

  try {
    const result = await axios.post(url, fields_data);
    if (result.status === 201) return result.data;
    else return null;
  } catch (error) {
    return null;
  }
};

export const getStandardFields = async (project_type, category) => {
  // ToDo: Assert project type is valid ["multifamily", ...]
  const url = `${baseUrl}/standard/fields/${project_type}?category=${category}`;

  try {
    const result = await axios.get(url);
    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    // console.log(JSON.parse(JSON.stringify(error)));
    return null;
  }
};

// Estimate

export const getEstimateRecord = async (record_uid) => {
  if (!record_uid) return null;

  const url = `${baseUrl}/estimate/records/${record_uid}`;

  try {
    const result = await axios.get(url);
    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    return null;
  }
};

export const getEstimateComps = async () => {
  const url = `${baseUrl}/estimate/comps`;

  try {
    const result = await axios.get(url);
    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    return null;
  }
};

export const getMarketCompUnitCosts = async (compRecords, marketUom, escalation, hard_costs_only) => {
  const url = `${baseUrl}/estimate/records/market/median`;

  try {
    const result = await axios.post(url, {
      compRecords: compRecords,
      bucketUom: marketUom,
      escalation: escalation,
      hard_costs_only: hard_costs_only
    });

    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    return null;
  }
};

export const getEstimateBuckets = async (selectedTemplate, compRecords, excludeComps = false) => {
  const url = `${baseUrl}/estimate/buckets?excludeComps=${excludeComps}`;

  try {
    const result = await axios.post(url, {
      compRecords: compRecords,
      selectedTemplate: selectedTemplate
    });
    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    throw Error(error);
  }
};

export const saveEstimateConfig = async (record_uid, config_name, config_value) => {
  if (!record_uid || !config_name || !config_value) return null;

  const url = `${baseUrl}/estimate/${record_uid}/config/${config_name}`;

  try {
    const result = await axios.put(url, config_value);
    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    return null;
  }
};

export const loadEstimateConfig = async (record_uid) => {
  if (!record_uid) return null;

  const url = `${baseUrl}/estimate/${record_uid}/config`;

  try {
    const result = await axios.get(url);
    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    return null;
  }
};

export const getEstimateFields = async (selectedTemplate, compRecords, fieldsUoms, escalation, excludeComps = false, childrenEstimateRecordUids = []) => {
  const url = `${baseUrl}/estimate/fields?excludeComps=${excludeComps}`;

  try {
    const result = await axios.post(url, {
      compRecords: compRecords,
      selectedTemplate: selectedTemplate,
      fieldsUoms: fieldsUoms,
      escalation: escalation,
      childrenEstimateRecordUids: childrenEstimateRecordUids
    });
    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    return null;
  }
};

export const getEstimateConsolidatedSoftCosts = async (childrenEstimateRecordUids = []) => {
  const url = `${baseUrl}/estimate/fields/soft_costs`;

  try {
    const result = await axios.post(url, {
      childrenEstimateRecordUids: childrenEstimateRecordUids
    });
    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    return null;
  }
};

export const getEstimateCompDesignFields = async (selectedTemplate, compRecords) => {
  const url = `${baseUrl}/estimate/comp_design_fields`;

  try {
    const result = await axios.post(url, {
      compRecords: compRecords,
      selectedTemplate: selectedTemplate
    });
    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    return null;
  }
};

export const getRecordTemplates = async () => {
  try {
    const url = `${baseUrl}/records/templates`;

    const result = await axios.get(url);
    return result.data;
  } catch (err) {
    throw Error(err);
  }
};

export const getRecordTemplate = async (template_uid) => {
  try {
    const url = `${baseUrl}/records/templates/${template_uid}`;
    const result = await axios.get(url);
    return result.data;
  } catch (err) {
    throw Error(err);
  }
};

export const getRecordTotalCostSum = async (recordUid) => {
  const url = `${baseUrl}/stats/record/${recordUid}/totalCostSum`;

  try {
    const result = await axios.get(url);
    return result.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getAllCostBucketsTotalCost = async (recordUid) => {
  const url = `${baseUrl}/record/${recordUid}/stats/buckets/totalCosts`;

  const result = await axios.get(url);

  if (result.status === 200) return result.data;
  else return null;
};

export const getMarketUnitCost = async (chartConfig, stdCostItemUid, stdDesignItemUid) => {
  const url = `${baseUrl}/stats/charts/market/cost/${stdCostItemUid}/per/${stdDesignItemUid}`;

  const result = await axios.post(url, chartConfig);

  if (result.status === 200) return result.data;
  else return null;
};

export const getMarketUnitCostStats = async (chartConfig, stdCostItemUid, stdDesignItemUid) => {
  const url = `${baseUrl}/stats/charts/market/cost/${stdCostItemUid}/per/${stdDesignItemUid}/stats`;

  const result = await axios.post(url, chartConfig);

  if (result.status === 200) return result.data;
  else return null;
};

export const getUnitCostBreakDownData = async (chartConfig, record_uid, unitOfmeasure) => {
  if (!!record_uid && !!unitOfmeasure) {
    const url = `${baseUrl}/records/${record_uid}/analytics/unit_cost_breakdown?escalation=0&uom=${unitOfmeasure}`;

    const result = await axios.post(url, chartConfig);

    if (result.status === 200) return result.data;
    else return null;
  } else {
    return null;
  }
};

export const getCompanyEscalationRates = async () => {
  try {
    const url = `${baseUrl}/company/settings/escalations/rates/all`;
    const result = await axios.get(url);
    return JSON.parse(result.data[0].setting_value);
  } catch (err) {
    throw Error(err);
  }
};

// =================================================================================
// =================================================================================
// =================================================================================

export const createEventSource = async (eventSourcePath) => {
  const eventSource = new EventSource(`${baseUrl}${eventSourcePath}`);
  return eventSource;
};

const httpClient = {
  // Project Management
  getProjects,

  // Record Management
  updateRecordParameter,
  deleteProjectRecord,
  getUnitCostBreakDownData,

  // Bucket Management
  createBucket,
  createBuckets,
  deleteAllBuckets,
  // Field Management
  getRecordFields,
  getRecordBuckets,

  createRecordFieldsBulk,
  createRecordFieldsSuperBulk,
  getStandardFields,
  // Estimate
  getEstimateRecord,
  getEstimateComps,
  getMarketCompUnitCosts,
  getEstimateBuckets,
  saveEstimateConfig,
  loadEstimateConfig,
  getEstimateFields,
  getEstimateConsolidatedSoftCosts,
  getEstimateCompDesignFields,

  //Compare Template
  getCompareLayouts,
  getCompareLayoutById,
  createNewLayout,
  deleteLayout,
  renameCompareLayout,

  // Template Management
  getRecordTemplates,
  getRecordTemplate,

  getAllCostBucketsTotalCost,
  getRecordTotalCostSum,
  getMarketUnitCost,
  getMarketUnitCostStats,
  // Private Stats
  // Escalation Rates
  getCompanyEscalationRates,
  // Event Sources
  createEventSource
};

export default httpClient;
