const getApiUrl = (apiVersion = 'v1') => {
  if (apiVersion === 'v2') {
    if (window.location.origin.includes('localhost')) return `http://localhost:${process.env.API_PORT}/v1`;
    if (window.location.origin.includes('app.zebel.io')) return 'https://api.v2.zebel.io/v1';
    if (window.location.origin.includes('app.dev.zebel.io')) return 'https://api.v2.dev.zebel.io/v1';
    if (window.location.origin.includes('amazon.zebel.io')) return 'https://api.v2.dev.zebel.io/v1';
  }
  if (window.location.origin.includes('localhost')) return 'http://localhost:5000';
  if (window.location.origin.includes('app.dev.zebel.io')) return 'https://api.dev.zebel.io';
  if (window.location.origin.includes('amazon.zebel.io')) return 'https://api.dev.zebel.io';
  if (window.location.origin.includes('app.zebel.io')) return 'https://api.zebel.io';
};

const getCookieDomain = () => {
  if (window.location.origin.includes('localhost')) return 'localhost';
  if (window.location.origin.includes('app.zebel.io')) return '.app.zebel.io';
  if (window.location.origin.includes('app.dev.zebel.io')) return '.app.dev.zebel.io';
  if (window.location.origin.includes('amazon.zebel.io')) return '.amazon.zebel.io';
};

export const formatAndThrowApiError = (err) => {
  let errMessage = 'Something went wrong.';
  if (err.response?.data?.message) {
    errMessage = err.response.data.message;
  } else if (err.message) {
    errMessage = err.message;
  }
  throw Error(errMessage);
};

export { getApiUrl, getCookieDomain };
