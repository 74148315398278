import { Suspense, lazy } from 'react';
import { LinearProgress } from '@mui/material';
import { useDocumentTitle, useLockBodyScroll } from 'rooks';
import { Redirect, Switch, Route, useHistory } from 'react-router-dom';
import { ErrorBoundary } from '@rollbar/react';

import AppHeader from './components/appHeader/AppHeader';
import ErrorDisplay from './components/app/ErrorDisplay';
import { PageLoading } from './components/app/Page';

import { useCompany } from './data/company';
import { useSelf } from './data/user';
import { Error } from './components/ui/error';
import { useAuth } from '@/helpers/auth0';

// Helper function to reload the page with a retry parameter
const reloadPage = () => {
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set('retry', '1');
  window.location.href = currentUrl.href;
};

// Helper function to check if the page has been reloaded with the retry parameter
const hasRetried = () => {
  const currentUrl = new URL(window.location.href);
  return currentUrl.searchParams.get('retry') === '1';
};

// Helper function to retry an async function a given number of times
const retryAsync = async (asyncFn, retriesLeft = 5, interval = 1000) => {
  try {
    return await asyncFn();
  } catch (error) {
    if (retriesLeft <= 1) {
      // If no retries left, and the page has not been reloaded yet, refresh the page
      if (!hasRetried()) {
        reloadPage();
      } else {
        throw error; // Give up after one retry
      }
    } else {
      // Wait for interval before retrying
      await new Promise((resolve) => setTimeout(resolve, interval));
      // Recursively retry the function
      return retryAsync(asyncFn, retriesLeft - 1, interval);
    }
  }
};

// Function to load a component with retry logic using async/await
const loadComponentWithRetry = (importFunc) => {
  const Component = lazy(() =>
    retryAsync(importFunc).catch((error) => {
      throw error;
    })
  );

  // Return a component that uses React.Suspense to display a fallback
  // while the lazy component is being loaded
  return (props) => (
    <Suspense fallback={<PageLoading />}>
      <Component {...props} />
    </Suspense>
  );
};

const AccountSetupPage = loadComponentWithRetry(() => import('./pages/accountSetup/Page'));
const EstimateOldListPage = loadComponentWithRetry(() => import('./pages/estimate-old/EstimateListPage'));
const EstimateOldPage = loadComponentWithRetry(() => import('./pages/estimate-old/estimatePage'));
const ComparePage = loadComponentWithRetry(() => import('./pages/compare/Page'));
const DashboardPage = loadComponentWithRetry(() => import('./pages/dashboard/Page'));
const SettingsPage = loadComponentWithRetry(() => import('./pages/settings/Page'));
const LoginPage = loadComponentWithRetry(() => import('./pages/login/Page'));
const EstimatePage = loadComponentWithRetry(() => import('./pages/estimate/Estimate'));
const WhatsNewPage = loadComponentWithRetry(() => import('./pages/product/ListProductNews'));
const ProjectsRoutes = loadComponentWithRetry(() => import('./pages/project/Routes'));
const ProjectRecordsRoutes = loadComponentWithRetry(() => import('./pages/projectRecord/Routes'));

const Projects = () => {
  useDocumentTitle('Zebel - Projects', { resetOnUnmount: true });
  useLockBodyScroll(false);
  return <ProjectsRoutes />;
};

const ProjectRecords = () => {
  useDocumentTitle('Zebel - Project Records', { resetOnUnmount: true });
  useLockBodyScroll(false);
  return <ProjectRecordsRoutes />;
};

// todo: estimate still using old layout
const EstimateOld = () => {
  useDocumentTitle('Zebel - Estimate', { resetOnUnmount: true });
  useLockBodyScroll(true);
  return <EstimateOldPage />;
};

// todo: routes below can have useLockBodyScroll removed after projects and estimate are updated
const EstimateOldList = () => {
  useDocumentTitle('Zebel - Estimate', { resetOnUnmount: true });
  useLockBodyScroll(false);
  return <EstimateOldListPage />;
};

const Compare = () => {
  useDocumentTitle('Zebel - Compare', { resetOnUnmount: true });
  useLockBodyScroll(false);
  return <ComparePage />;
};

const Dashboard = () => {
  useDocumentTitle('Zebel - Market', { resetOnUnmount: true });
  useLockBodyScroll(false);
  return <DashboardPage />;
};

const Settings = () => {
  useDocumentTitle('Zebel - Settings', { resetOnUnmount: true });
  useLockBodyScroll(false);
  return <SettingsPage />;
};

const AccountSetup = () => {
  useDocumentTitle('Zebel - Account Setup', { resetOnUnmount: true });
  useLockBodyScroll(false);
  return <AccountSetupPage />;
};

const Estimate = () => {
  useDocumentTitle('Zebel - Estimate', { resetOnUnmount: true });
  useLockBodyScroll(false);
  return <EstimatePage />;
};

const ProductNews = () => {
  useDocumentTitle('Zebel - Product News', { resetOnUnmount: true });
  useLockBodyScroll(false);
  return <WhatsNewPage />;
};

const AuthenticatedRoutes = () => {
  const history = useHistory();
  const { authStrategy, isAuthenticated, isLoading, loginWithRedirect } = useAuth();

  if (isLoading) {
    return <LinearProgress />;
  }

  if (!isAuthenticated) {
    if (authStrategy === 'zebel-api') {
      history.push('/login');
    } else {
      loginWithRedirect();
    }
    return null;
  }

  return (
    <UserLoader>
      <AppHeader />
      <ErrorBoundary fallbackUI={ErrorDisplay}>
        <Switch>
          <Redirect exact from={['/', '/projects']} to="/project" />

          <Route path="/project" component={Projects} />
          <Route path="/project-record" component={ProjectRecords} />
          <Route path="/settings" component={Settings} />
          <Route path="/compare" component={Compare} />
          <Route path={['/estimate/:consolidated_record_uid/children/:record_uid', '/estimate/:record_uid']} component={EstimateOld} />
          <Route path="/estimate" component={EstimateOldList} />
          <Route path="/market" component={Dashboard} />
          <Route path="/v2-estimate" component={Estimate} />
          <Route path="/updates" component={ProductNews} />
        </Switch>
      </ErrorBoundary>
    </UserLoader>
  );
};

const UserLoader = ({ children }) => {
  const company = useCompany();
  const self = useSelf();

  if (company.isLoading && !company.data) {
    return <LinearProgress />;
  }

  if (company.error) {
    return <Error message="An error occurred loading company" />;
  }

  if (self.isLoading && !self.data) {
    return <LinearProgress />;
  }

  // todo: this can be refactored as an auth check instead
  if (self.error) {
    return <Error message="An error occurred loading user" />;
  }

  return children;
};

const ActivateRoutes = () => {
  return (
    <Switch>
      <Route path="/users/activate/:activationUid" component={AccountSetup} />
    </Switch>
  );
};

const LoginRoutes = () => {
  return (
    <Switch>
      <Route path="/login" component={LoginPage} />
    </Switch>
  );
};

export { AuthenticatedRoutes, ActivateRoutes, LoginRoutes };
