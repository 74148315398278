import moment from 'moment';

import { formatNumber } from '@/helpers/numberHelpers';

const getFieldNameForUOMMetric = (compare, metric) => {
  if (!metric.standard_uom || metric.standard_uom === '-') {
    return 'Total';
  }

  const designFields = compare.comparingRecords[compare.mainComparingRecordUid].fields.data.designFields;

  if (metric.uom_type === 'custom') {
    const field = Object.keys(designFields).find((designField) => designFields[designField].uid === metric.standard_uom);
    if (field) {
      return designFields[field].field_name;
    }
  } else {
    const field = Object.keys(designFields).find((designField) => designFields[designField].standard_field === metric.standard_uom);
    if (field) {
      return designFields[field].field_name;
    }
  }

  return 'N/A';
};

const getDesignMetricValue = (compare, designMetric, comparingRecordUid, valueUnits) => {
  const comparingRecord = compare.comparingRecords[comparingRecordUid];

  const designFields = comparingRecord.fields.data.designFields;
  const formattedFieldName = designMetric.field_name.trim().toLowerCase();
  let field;

  // Try to match by exact name first
  let matchedDesignFieldByName = Object.keys(designFields).find((designFieldUid) => designFields[designFieldUid].field_name === designMetric.field_name);

  // If not find by similar
  if (!matchedDesignFieldByName) {
    matchedDesignFieldByName = Object.keys(designFields).find((designFieldUid) => designFields[designFieldUid].field_name.trim().toLowerCase().includes(formattedFieldName));
  }

  if (matchedDesignFieldByName) {
    field = designFields[matchedDesignFieldByName];
  }

  if (!field && designMetric.standard_field) {
    // If no name match try to match by standard field
    const matchedDesignFieldByStandardField = Object.keys(designFields).find(
      (designFieldUid) => designFields[designFieldUid].standard_field && designFields[designFieldUid].standard_field === designMetric.standard_field
    );
    if (matchedDesignFieldByStandardField) {
      field = designFields[matchedDesignFieldByStandardField];
    }
  }

  let valueUnitLabel;
  let valueUnitDecimals = 0;
  if (field && field.value_unit_uid && valueUnits && valueUnits.content) {
    const valueUnit = valueUnits.content[field.value_unit_uid];
    if (valueUnit) {
      valueUnitLabel = valueUnit.label;
      valueUnitDecimals = valueUnit.decimals;
    }
  }

  if (comparingRecord.type === 'market') {
    let marketCost = compare.comparingRecords[comparingRecordUid].marketData?.['00000000000000000000000000000000']?.[designMetric.standard_field];
    if (!marketCost) {
      return null;
    }
    return { ...marketCost, valueUnit: valueUnitLabel };
  }

  if (!field) {
    return {
      error: 'No cost is set for this design metric'
    };
  }

  if (!valueUnitLabel) {
    return {
      totalCost: field.value,
      formattedTotalCost: field.value,
      valueUnit: valueUnitLabel
    };
  }

  if (!isValid(field.value)) {
    return {
      error: 'No cost is set for this design metric'
    };
  }

  let totalCost = parseFloat(field.value);
  let formattedTotalCost;
  formattedTotalCost = formatNumber(totalCost, valueUnitDecimals);

  return {
    totalCost: totalCost,
    formattedTotalCost,
    valueUnit: valueUnitLabel
  };
};

const getCostMetricValue = (compare, costMetric, comparingRecordUid) => {
  const comparingRecord = compare.comparingRecords[comparingRecordUid];

  if (comparingRecord.type === 'market') {
    return null;
  }

  const costFields = comparingRecord.fields.data.costFields;
  const designFields = comparingRecord.fields.data.designFields;
  const effectiveEscalation = comparingRecord.escalationConfig.effectiveEscalation;
  let effectiveRegionalAdjustment = 0;
  if (comparingRecord.isMsaPremium) {
    effectiveRegionalAdjustment = comparingRecord.effectiveRegionalAdjustment;
  }
  let totalCost;

  // first try to match directly from bucket and cost codes
  const matchedCostFieldUid = Object.keys(costFields).find((costFieldUid) => {
    const costField = costFields[costFieldUid];
    return costField.bucket_code === costMetric.bucket_code && costField.code === costMetric.code;
  });

  if (matchedCostFieldUid) {
    const matchedField = costFields[matchedCostFieldUid];
    const costFieldValue = matchedField.value;
    totalCost = parseFloat(costFieldValue);
    if ((!costMetric.standard_uom || costMetric.standard_uom === '-') && isValid(totalCost)) {
      const escalatedTotalCost = totalCost * (1 + effectiveEscalation / 100) * (1 + effectiveRegionalAdjustment / 100);
      return {
        totalCost: escalatedTotalCost,
        formattedTotalCost: `$${formatNumber(escalatedTotalCost, 2)}`
      };
    }
  }

  let matchedDesignFieldUid;
  let designFieldValue;
  if (!costMetric.standard_uom || costMetric.standard_uom === '-') {
    designFieldValue = 1;
  } else {
    if (costMetric.uom_type === 'custom') {
      // next try to match by standard uom
      matchedDesignFieldUid = Object.keys(designFields).find((designFieldUid) => designFieldUid === costMetric.standard_uom);
      // lookup the design field by name if no match
      if (!matchedDesignFieldUid) {
        const formattedFieldName = costMetric.uom_name.trim().toLowerCase();
        matchedDesignFieldUid = Object.keys(designFields).find((designFieldUid) => designFields[designFieldUid].field_name?.trim().toLowerCase() === formattedFieldName);
      }
    } else {
      matchedDesignFieldUid = Object.keys(designFields).find((designFieldUid) => designFields[designFieldUid].standard_field === costMetric.standard_uom);
    }

    if (matchedDesignFieldUid) {
      designFieldValue = designFields[matchedDesignFieldUid].value;
    }
  }

  if (!isValid(designFieldValue)) {
    return {
      error: 'The chosen unit of measure for this field is not connected to market data and no similar named field could be found.'
    };
  }

  totalCost = parseFloat(totalCost);
  designFieldValue = parseFloat(designFieldValue);

  if (!isValid(totalCost) || !isValid(designFieldValue)) {
    return {
      error: 'No Cost is set for this record item'
    };
  }

  if (totalCost === 0 || designFieldValue === 0) {
    return {
      totalCost: 0,
      formattedTotalCost: `$${formatNumber(0)}`
    };
  }

  const escalatedTotalCost = (totalCost / designFieldValue) * (1 + effectiveEscalation / 100) * (1 + effectiveRegionalAdjustment / 100);

  return {
    totalCost: escalatedTotalCost,
    formattedTotalCost: `$${formatNumber(escalatedTotalCost, 2)}`
  };
};

const getCostFieldGroupValue = (compare, costFieldGroup, comparingRecordUid) => {
  const comparingRecord = compare.comparingRecords[comparingRecordUid];

  if (comparingRecord.type === 'market') {
    return null;
  }

  const costFieldGroups = comparingRecord.fields.data.costFieldGroups;
  const designFields = comparingRecord.fields.data.designFields;
  const costFields = comparingRecord.fields.data.costFields;
  const effectiveEscalation = comparingRecord.escalationConfig.effectiveEscalation;
  let effectiveRegionalAdjustment = 0;
  if (comparingRecord.isMsaPremium) {
    effectiveRegionalAdjustment = comparingRecord.effectiveRegionalAdjustment;
  }
  let totalCost;
  let designFieldValue;

  const matchedCostFieldGroupUid = Object.keys(costFieldGroups).find((costFieldGroupUid) => {
    const group = costFieldGroups[costFieldGroupUid];
    return group.label === costFieldGroup.label && group.cost_code_group === costFieldGroup.cost_code_group && group.bucket_name === costFieldGroup.bucket_name;
  });

  if (!matchedCostFieldGroupUid || Object.keys(costFieldGroups).length === 1) {
    return {
      error: 'The chosen cost code group does not exist in this record.'
    };
  }

  if (!costFieldGroup.standard_uom || costFieldGroup.standard_uom === '-') {
    designFieldValue = 1;
  } else {
    let matchedDesignFieldUid;
    if (costFieldGroup.uom_type === 'custom') {
      // next try to match by standard uom
      matchedDesignFieldUid = Object.keys(designFields).find((designFieldUid) => designFieldUid === costFieldGroup.standard_uom);
      // lookup the design field by name if no match
      if (!matchedDesignFieldUid) {
        const formattedFieldName = costFieldGroup.uom_name.trim().toLowerCase();
        matchedDesignFieldUid = Object.keys(designFields).find((designFieldUid) => designFields[designFieldUid].field_name?.trim().toLowerCase() === formattedFieldName);
      }
    } else {
      matchedDesignFieldUid = Object.keys(designFields).find((designFieldUid) => designFields[designFieldUid].standard_field === costFieldGroup.standard_uom);
    }

    if (matchedDesignFieldUid) {
      designFieldValue = designFields[matchedDesignFieldUid].value;
    }
  }

  if (!isValid(designFieldValue)) {
    return {
      error: 'The chosen unit of measure for this field is not connected to market data and no similar named field could be found.'
    };
  }

  costFieldGroups[matchedCostFieldGroupUid].fields.forEach((field) => {
    const fieldUid = Object.keys(costFields).find((fuid) => costFields[fuid].code === field.code && costFields[fuid].bucket_code === field.bucket_code);

    if (fieldUid) {
      if (!totalCost) {
        totalCost = 0;
      }

      totalCost += parseFloat(costFields[fieldUid].value || 0);
    }
  });

  totalCost = parseFloat(totalCost);
  designFieldValue = parseFloat(designFieldValue);

  if (!isValid(totalCost) || !isValid(designFieldValue)) {
    return {
      error: 'No Cost is set for this record item'
    };
  }

  if (totalCost === 0 || designFieldValue === 0) {
    return {
      totalCost: 0,
      formattedTotalCost: `$${formatNumber(0)}`
    };
  }

  const escalatedTotalCost = (totalCost / designFieldValue) * (1 + effectiveEscalation / 100) * (1 + effectiveRegionalAdjustment / 100);

  return {
    totalCost: escalatedTotalCost,
    formattedTotalCost: `$${formatNumber(escalatedTotalCost, 2)}`
  };
};

const getCostCategoryValue = (compare, costCategory, comparingRecordUid) => {
  const comparingRecord = compare.comparingRecords[comparingRecordUid];

  if (comparingRecord.type === 'market') {
    return null;
  }

  const designFields = comparingRecord.fields.data.designFields;
  const costCategories = comparingRecord.fields.data.costCategories;
  const effectiveEscalation = comparingRecord.escalationConfig.effectiveEscalation;
  let effectiveRegionalAdjustment = 0;
  if (comparingRecord.isMsaPremium) {
    effectiveRegionalAdjustment = comparingRecord.effectiveRegionalAdjustment;
  }
  let totalCost;
  let designFieldValue;

  const costCategoryUid = Object.keys(costCategories).find(
    (uid) => costCategories[uid].bucket_code === costCategory.bucket_code && costCategories[uid].bucket_name === costCategory.bucket_name
  );

  if (!costCategoryUid) {
    return {
      error: 'The chosen cost category does not exist in this record.'
    };
  }

  if (!costCategory.standard_uom || costCategory.standard_uom === '-') {
    designFieldValue = 1;
  } else {
    let matchedDesignFieldUid;
    if (costCategory.uom_type === 'custom') {
      // next try to match by standard uom
      matchedDesignFieldUid = Object.keys(designFields).find((designFieldUid) => designFieldUid === costCategory.standard_uom);
      // lookup the design field by name if no match
      if (!matchedDesignFieldUid) {
        const formattedCategoryName = costCategory.uom_name.trim().toLowerCase();
        matchedDesignFieldUid = Object.keys(designFields).find((designFieldUid) => designFields[designFieldUid].field_name?.trim().toLowerCase() === formattedCategoryName);
      }
    } else {
      matchedDesignFieldUid = Object.keys(designFields).find((designFieldUid) => designFields[designFieldUid].standard_field === costCategory.standard_uom);
    }

    if (matchedDesignFieldUid) {
      designFieldValue = designFields[matchedDesignFieldUid].value;
    }
  }

  if (!isValid(designFieldValue)) {
    return {
      error: 'The chosen unit of measure for this field is not connected to market data and no similar named field could be found.'
    };
  }

  totalCost = parseFloat(costCategories[costCategoryUid].total_cost);
  designFieldValue = parseFloat(designFieldValue);

  if (!isValid(totalCost) || !isValid(designFieldValue)) {
    return {
      error: 'No Cost is set for this record item'
    };
  }

  if (totalCost === 0 || designFieldValue === 0) {
    return {
      totalCost: 0,
      formattedTotalCost: `$${formatNumber(0)}`
    };
  }

  const escalatedTotalCost = (totalCost / designFieldValue) * (1 + effectiveEscalation / 100) * (1 + effectiveRegionalAdjustment / 100);

  return {
    totalCost: escalatedTotalCost,
    formattedTotalCost: `$${formatNumber(escalatedTotalCost, 2)}`
  };
};

const getCostCategoryGroupValue = (compare, costCategoryGroup, comparingRecordUid) => {
  const comparingRecord = compare.comparingRecords[comparingRecordUid];

  if (comparingRecord.type === 'market') {
    return null;
  }

  const designFields = comparingRecord.fields.data.designFields;
  const costCategories = comparingRecord.fields.data.costCategories;
  const costCategoryGroups = comparingRecord.fields.data.costCategoryGroups;
  const effectiveEscalation = comparingRecord.escalationConfig.effectiveEscalation;
  let effectiveRegionalAdjustment = 0;
  if (comparingRecord.isMsaPremium) {
    effectiveRegionalAdjustment = comparingRecord.effectiveRegionalAdjustment;
  }
  let totalCost;
  let designFieldValue;

  const costCategoryGroupUid = Object.keys(costCategoryGroups).find((uid) => costCategoryGroups[uid].label === costCategoryGroup.label);

  if (!costCategoryGroupUid) {
    return {
      error: 'The selected cost category group does not exist in this record.'
    };
  }

  if (!costCategoryGroup.standard_uom || costCategoryGroup.standard_uom === '-') {
    designFieldValue = 1;
  } else {
    let matchedDesignFieldUid;
    if (costCategoryGroup.uom_type === 'custom') {
      // next try to match by standard uom
      matchedDesignFieldUid = Object.keys(designFields).find((designFieldUid) => designFieldUid === costCategoryGroup.standard_uom);
      // lookup the design field by name if no match
      if (!matchedDesignFieldUid) {
        const formattedCategoryGroupName = costCategoryGroup.uom_name.trim().toLowerCase();
        matchedDesignFieldUid = Object.keys(designFields).find((designFieldUid) => designFields[designFieldUid].field_name?.trim().toLowerCase() === formattedCategoryGroupName);
      }
    } else {
      matchedDesignFieldUid = Object.keys(designFields).find((designFieldUid) => designFields[designFieldUid].standard_field === costCategoryGroup.standard_uom);
    }

    if (matchedDesignFieldUid) {
      designFieldValue = designFields[matchedDesignFieldUid].value;
    }
  }

  if (!isValid(designFieldValue)) {
    return {
      error: 'The chosen unit of measure for this field is not connected to market data and no similar named field could be found.'
    };
  }

  costCategoryGroups[costCategoryGroupUid].buckets.forEach((b) => {
    const bucket = costCategories[b.uid];
    if (bucket) {
      if (!totalCost) {
        totalCost = 0;
      }
      totalCost += parseFloat(bucket.total_cost);
    }
  });

  totalCost = parseFloat(totalCost);
  designFieldValue = parseFloat(designFieldValue);

  if (!isValid(totalCost) || !isValid(designFieldValue)) {
    return {
      error: 'No Cost is set for this record item'
    };
  }

  if (totalCost === 0 || designFieldValue === 0) {
    return {
      totalCost: 0,
      formattedTotalCost: `$${formatNumber(0)}`
    };
  }

  const escalatedTotalCost = (totalCost / designFieldValue) * (1 + effectiveEscalation / 100) * (1 + effectiveRegionalAdjustment / 100);

  return {
    totalCost: escalatedTotalCost,
    formattedTotalCost: `$${formatNumber(escalatedTotalCost, 2)}`
  };
};

const getCSIDivisionValue = (compare, csiDivision, comparingRecordUid) => {
  const comparingRecord = compare.comparingRecords[comparingRecordUid];

  if (comparingRecord.type === 'market') {
    const marketCost = compare.comparingRecords[comparingRecordUid].marketData?.[csiDivision.division_uid]?.[csiDivision.standard_uom || '-'];
    if (!marketCost) {
      return null;
    }
    // If the uom selected is 'total', the return value is a different object key because they api returns it that way
    if (!csiDivision.standard_uom || csiDivision.standard_uom === '-') {
      return {
        ...marketCost,
        data: {
          max: marketCost.data.max_total,
          median: marketCost.data.median_total,
          min: marketCost.data.min_total
        }
      };
    }
    return marketCost;
  }

  const designFields = comparingRecord.fields.data.designFields;
  const csiDivisions = comparingRecord.fields.data.csiDivisions;
  const effectiveEscalation = comparingRecord.escalationConfig.effectiveEscalation;
  let effectiveRegionalAdjustment = 0;
  if (comparingRecord.isMsaPremium) {
    effectiveRegionalAdjustment = comparingRecord.effectiveRegionalAdjustment;
  }

  let totalCost;
  let designFieldValue;

  const matchedCSIDivision = Object.keys(csiDivisions).find((csiUid) => csiDivisions[csiUid].division_code === csiDivision.division_code);

  if (!matchedCSIDivision) {
    return {
      error: 'The chosen CSI 16 division does not exist in this record.'
    };
  }

  if (!csiDivision.standard_uom || csiDivision.standard_uom === '-') {
    designFieldValue = 1;
  } else {
    let matchedDesignFieldUid;
    if (csiDivision.uom_type === 'custom') {
      // next try to match by standard uom
      matchedDesignFieldUid = Object.keys(designFields).find((designFieldUid) => designFieldUid === csiDivision.standard_uom);
      // lookup the design field by name if no match
      if (!matchedDesignFieldUid) {
        const formattedFieldName = csiDivision.uom_name.trim().toLowerCase();
        matchedDesignFieldUid = Object.keys(designFields).find((designFieldUid) => designFields[designFieldUid].field_name?.trim().toLowerCase() === formattedFieldName);
      }
    } else {
      matchedDesignFieldUid = Object.keys(designFields).find((designFieldUid) => designFields[designFieldUid].standard_field === csiDivision.standard_uom);
    }

    if (matchedDesignFieldUid) {
      designFieldValue = designFields[matchedDesignFieldUid].value;
    }
  }

  if (!isValid(designFieldValue)) {
    return {
      error: 'The chosen unit of measure for this field is not connected to market data and no similar named field could be found.'
    };
  }

  totalCost = parseFloat(csiDivisions[matchedCSIDivision].total_division_cost);
  designFieldValue = parseFloat(designFieldValue);

  if (!isValid(totalCost) || !isValid(designFieldValue)) {
    return {
      error: 'No Cost is set for this record item'
    };
  }

  if (totalCost === 0 || designFieldValue === 0) {
    return {
      totalCost: 0,
      formattedTotalCost: `$${formatNumber(0)}`
    };
  }

  const escalatedTotalCost = (totalCost / designFieldValue) * (1 + effectiveEscalation / 100) * (1 + effectiveRegionalAdjustment / 100);

  return {
    totalCost: escalatedTotalCost,
    formattedTotalCost: `$${formatNumber(escalatedTotalCost, 2)}`
  };
};

const getTotalCostValue = (compare, totalCostItem, comparingRecordUid) => {
  const comparingRecord = compare.comparingRecords[comparingRecordUid];

  if (comparingRecord.type === 'market') {
    const marketCost = compare.comparingRecords[comparingRecordUid].marketData?.['00000000000000000000000000000000']?.[totalCostItem.standard_uom || '-'];
    if (!marketCost) {
      return null;
    }
    // If the uom selected is 'total', the return value is a different object key because they api returns it that way
    if (!totalCostItem.standard_uom || totalCostItem.standard_uom === '-') {
      return {
        ...marketCost,
        data: {
          max: marketCost.data.max_total,
          median: marketCost.data.median_total,
          min: marketCost.data.min_total
        }
      };
    }
    return marketCost;
  }

  const designFields = comparingRecord.fields.data.designFields;
  const effectiveEscalation = comparingRecord.escalationConfig.effectiveEscalation;
  let effectiveRegionalAdjustment = 0;
  if (comparingRecord.isMsaPremium) {
    effectiveRegionalAdjustment = comparingRecord.effectiveRegionalAdjustment;
  }
  let totalCost = comparingRecord.fields.data.totalCost?.total_cost;
  let designFieldValue;
  let matchedDesignFieldUid;

  if (!isValid(totalCost)) {
    return {
      error: 'No Cost is set for this record item.'
    };
  }

  if (!totalCostItem.standard_uom || totalCostItem.standard_uom === '-') {
    designFieldValue = 1;
  } else {
    if (totalCostItem.uom_type === 'custom') {
      // next try to match by standard uom
      matchedDesignFieldUid = Object.keys(designFields).find((designFieldUid) => designFieldUid === totalCostItem.standard_uom);
      // lookup the design field by name if no match
      if (!matchedDesignFieldUid) {
        const formattedFieldName = totalCostItem.uom_name.trim().toLowerCase();
        matchedDesignFieldUid = Object.keys(designFields).find((designFieldUid) => designFields[designFieldUid].field_name?.trim().toLowerCase() === formattedFieldName);
      }
    } else {
      matchedDesignFieldUid = Object.keys(designFields).find((designFieldUid) => designFields[designFieldUid].standard_field === totalCostItem.standard_uom);
    }

    if (matchedDesignFieldUid) {
      designFieldValue = designFields[matchedDesignFieldUid].value;
    }
  }

  if (!isValid(designFieldValue)) {
    return {
      error: 'The chosen unit of measure for this field is not connected to market data and no similar named field could be found.'
    };
  }

  totalCost = parseFloat(totalCost);
  designFieldValue = parseFloat(designFieldValue);

  if (!isValid(totalCost)) {
    return {
      error: 'No total cost is set'
    };
  }

  if (totalCost === 0 || designFieldValue === 0) {
    return {
      totalCost: 0,
      formattedTotalCost: `$${formatNumber(0)}`
    };
  }

  const escalatedTotalCost = (totalCost / designFieldValue) * (1 + effectiveEscalation / 100) * (1 + effectiveRegionalAdjustment / 100);

  return {
    totalCost: escalatedTotalCost,
    formattedTotalCost: `$${formatNumber(escalatedTotalCost, 2)}`
  };
};

const isValid = (value) => {
  return ![null, undefined, ''].includes(value) && !isNaN(parseFloat(value));
};

const escalationConfigTemplate = {
  isEscalated: false,
  toDate: moment(Date.now()).format('YYYY-MM-DD'),
  escPercent: 5.0,
  effectiveEscalation: 0
};

const filterConfigTemplate = {
  escalation: {
    enabled: false
  },
  productType: {
    enabled: true,
    data: { selected: ['podium', 'wrap', 'wood_podium', 'slab_on_grade', 'high_rise', 'various'] }
  },
  constructionType: {
    enabled: false,
    data: { selected: ['Type I', 'Type II', 'Type III', 'Type V', 'Various'] }
  },
  projectType: {
    enabled: true,
    data: { selected: ['multifamily', 'student_housing', 'senior_housing'] }
  },
  region: {
    enabled: false,
    data: { regions: [] }
  },
  buyoutTime: {
    enabled: false,
    data: { selected: [100] }
  },
  projectStatus: {
    enabled: false,
    data: { selected: ['quick-estimate', 'schematic-design', 'pre-construction', 'construction-documents', 'contract', 'in-construction', 'completed'] }
  },
  rentalType: {
    enabled: false,
    data: { selected: ['market_rate', 'affordable', 'mixed_income'] }
  },
  union: {
    enabled: false,
    data: { selected: [0] }
  },
  unitCount: {
    enabled: false,
    data: { minCount: 0, maxCount: 10000 }
  },
  avgUnitSize: {
    enabled: false,
    data: { minCount: 400, maxCount: 2000 }
  }
};

export {
  getFieldNameForUOMMetric,
  getDesignMetricValue,
  getCostMetricValue,
  getCostFieldGroupValue,
  getCostCategoryValue,
  getCostCategoryGroupValue,
  getCSIDivisionValue,
  getTotalCostValue,
  escalationConfigTemplate,
  filterConfigTemplate
};
