import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import moment from 'moment';
import httpClient from '../../services/httpClient';
import { defaultProgrammingConfig, initialEstimateConfig, initialManualItems, UoMStandardDesignFields, stdDesignFields, designFields } from '../../pages/estimate-old/constants';
import {
  convertOldConfigData,
  convertOldDetailedEstimates,
  convertOldFieldCalculations,
  detailedEstimateTemplate,
  detailedEstimateSubFieldTemplate,
  indirectCostTemplate,
  getEstimateDesignValue,
  getFieldCost,
  getDetailedEstimateSubFieldCost,
  getFieldCompDesignValuesAndErrors,
  getBucketCompDesignValues,
  getIndirectCost,
  getTotalDirectCost,
  getTotalIndirectCosts,
  getFieldsFromEstimateProgrammingConfig
} from '../../pages/estimate-old/helpers';

import { API_STATUS_ENUM } from '@/helpers/enums';
import { calculateEffectiveEscalation } from '@/helpers/escalation';
import { isMultifamilyOrEquivalent } from '@/helpers/sector';

const standardFields = UoMStandardDesignFields();

const initialState = {
  primaryStep: 'generalInformation',
  activeTab: 'summary',
  isAdjustmentColumnVisible: localStorage.getItem('isAdjustmentColumnVisible') === 'true',
  isNotesColumnVisible: localStorage.getItem('isNotesColumnVisible') === 'true',
  isOverviewMinimized: false,
  isFullScreen: false,
  isConsolidated: false,
  isConsolidatedTemplatesValid: false,
  effectiveEscalation: {},
  fieldErrors: {},
  isSaving: false,
  isSavingError: false,
  fieldCalculations: {},
  fieldCalculationTotals: {},
  bucketCalculations: {},
  fieldGroupCalculations: {},
  bucketGroupCalculations: {},
  indirectCostTotals: {},
  detailedEstimates: {},
  detailedEstimateTotals: {},
  templates: {},
  manualItems: initialManualItems,
  selectedRecCostFields: {},
  estimateDesignValues: {},
  consolidatedSoftCosts: [],
  totalDirectCost: 0,
  totalIndirectCost: 0,
  fieldCompDesignValues: {},
  bucketCompDesignValues: {},
  savingConfigs: {
    ManualItems: false,
    EstimateConfig: false,
    FieldCalculations: false,
    SelectedRecCostFields: false,
    EstimateImagePath: false,
    BucketGroupCalculations: false,
    FieldGroupCalculations: false,
    BucketCalculations: false
  },
  config: {
    data: {},
    getApiStatus: API_STATUS_ENUM.PENDING
  },
  records: {
    data: {},
    getApiStatus: API_STATUS_ENUM.PENDING,
    postApiStatus: API_STATUS_ENUM.IDLE,
    putApiStatus: API_STATUS_ENUM.IDLE
  },
  deleteEstimate: {
    isOpen: false,
    deleteApiStatus: API_STATUS_ENUM.IDLE
  },
  renameEstimate: {
    isOpen: false,
    postApiStatus: API_STATUS_ENUM.IDLE
  },
  estimateComps: {
    getApiStatus: API_STATUS_ENUM.PENDING,
    data: {}
  },
  estimateCompsDesignFields: {
    getApiStatus: API_STATUS_ENUM.PENDING,
    data: {
      standardFields: {},
      nonStandardFields: {}
    }
  },
  designRecordFields: {
    getApiStatus: API_STATUS_ENUM.PENDING,
    data: {}
  },
  buckets: {
    data: {},
    groups: {},
    getApiStatus: API_STATUS_ENUM.PENDING
  },
  fields: {
    data: {},
    groups: {},
    buckets: {},
    getApiStatus: API_STATUS_ENUM.PENDING
  },
  compRecordsTotalCost: {},
  marketCompTotalCost: {}
};

export const getRecords = createAsyncThunk('getRecords', async (consolidatedRecordUid) => {
  try {
    const records = {};

    const [consolidatedRecord] = await httpClient.getEstimateRecord(consolidatedRecordUid);
    records[consolidatedRecord.uid] = { ...consolidatedRecord, estimate_children: consolidatedRecord.estimate_children?.split(',') };

    if (consolidatedRecord.estimate_children) {
      const childRecordIds = consolidatedRecord.estimate_children.split(',');
      for (const childRecordId of childRecordIds) {
        const [childRecord] = await httpClient.getEstimateRecord(childRecordId);
        records[childRecord.uid] = childRecord;
      }
    }

    return records;
  } catch (err) {
    throw Error(err);
  }
});

export const putRecord = createAsyncThunk('putRecord', async ({ projectUid, recordUid, key, value }) => {
  try {
    const result = await httpClient.updateRecordParameter(projectUid, recordUid, key, value);
    return result;
  } catch (err) {
    throw Error(err);
  }
});

export const deleteRecord = createAsyncThunk('deleteRecord', async ({ recordUid }) => {
  try {
    await httpClient.deleteProjectRecord(recordUid);
  } catch (err) {
    throw Error(err);
  }
});

export const getEstimateComps = createAsyncThunk('getEstimateComps', async () => {
  try {
    const result = await httpClient.getEstimateComps();
    const comps = {};
    result.forEach((comp) => {
      comps[comp.record_uid] = comp;
    });
    return comps;
  } catch (err) {
    throw Error(err);
  }
});

export const getEstimateCompsDesignFields = createAsyncThunk('getEstimateCompsDesignFields', async ({ selectedEstimateTemplate, compRecords }) => {
  try {
    const result = await httpClient.getEstimateCompDesignFields(selectedEstimateTemplate, compRecords);
    const standardFields = {};
    const nonStandardFields = {};
    result.forEach((field) => {
      if (!field.standard_field) {
        if (!nonStandardFields[field.record_uid]) {
          nonStandardFields[field.record_uid] = [];
        }
        nonStandardFields[field.record_uid].push(field);
      } else {
        if (!standardFields[field.record_uid]) {
          standardFields[field.record_uid] = {};
        }
        standardFields[field.record_uid][field.standard_field] = field;
      }
    });

    return { standardFields, nonStandardFields };
  } catch (err) {
    throw Error(err);
  }
});

export const getDesignRecordFields = createAsyncThunk('getDesignRecordFields', async ({ templateUid }) => {
  try {
    const result = await httpClient.getRecordFields(templateUid, 'Design', null, null, null);
    const buckets = {};
    const fields = {};
    result.forEach((field) => {
      if (!buckets[field.bucket]) {
        buckets[field.bucket] = {
          bucket_uid: field.bucket,
          bucket_name: field.bucket_name,
          fields: {}
        };
      }
      fields[field.uid] = { ...field };
      buckets[field.bucket].fields[field.uid] = { ...field };
    });
    return { buckets, fields };
  } catch (err) {
    throw Error(err);
  }
});

export const getConsolidatedSoftCosts = createAsyncThunk('getConsolidatedSoftCosts', async ({ recordUids }) => {
  try {
    const result = await httpClient.getEstimateConsolidatedSoftCosts(recordUids);
    return result;
  } catch (err) {
    throw Error(err);
  }
});

export const getBuckets = createAsyncThunk('getBuckets', async ({ selectedEstimateTemplate, compRecords, excludeComps }) => {
  try {
    const buckets = await httpClient.getEstimateBuckets(selectedEstimateTemplate, compRecords, excludeComps);
    let groups = {
      Other: []
    };
    Object.keys(buckets).forEach((bucketUid) => {
      const metadata = buckets[bucketUid].meta.et_bucket_metadata;
      if (metadata) {
        const group = JSON.parse(metadata)?.tags?.group;
        if (group) {
          if (!groups[group]) {
            groups[group] = [];
          }
          groups[group].push(bucketUid);
        } else {
          groups.Other.push(bucketUid);
        }
      } else {
        groups.Other.push(bucketUid);
      }
    });
    return { buckets, groups };
  } catch (err) {
    throw Error(err);
  }
});

export const getFields = createAsyncThunk('getFields', async ({ selectedEstimateTemplate, compRecords, initialFieldsUoms, escalationPerYear, excludeComps, recordUids }) => {
  try {
    const fields = await httpClient.getEstimateFields(selectedEstimateTemplate, compRecords, initialFieldsUoms, escalationPerYear, excludeComps, recordUids);
    let groups = {};
    let buckets = {};
    Object.keys(fields).forEach((fieldUid) => {
      const metadata = fields[fieldUid].meta.et_field_metadata;
      const bucketUid = fields[fieldUid].meta.et_field_bucket;

      if (!buckets[bucketUid]) {
        buckets[bucketUid] = [];
      }
      buckets[bucketUid].push(fieldUid);

      if (!groups[bucketUid]) {
        groups[bucketUid] = {
          Other: []
        };
      }

      if (metadata) {
        const group = JSON.parse(metadata)?.tags?.group;
        if (group) {
          if (!groups[bucketUid][group]) {
            groups[bucketUid][group] = [];
          }
          groups[bucketUid][group].push(fieldUid);
        } else {
          groups[bucketUid].Other.push(fieldUid);
        }
      } else {
        groups[bucketUid].Other.push(fieldUid);
      }
    });
    return { fields, groups, buckets };
  } catch (err) {
    throw Error(err);
  }
});

export const getCompRecordTotalCost = createAsyncThunk('getCompRecordTotalCost', async ({ recordUid }) => {
  try {
    const [result] = await httpClient.getRecordTotalCostSum(recordUid);
    return result;
  } catch (err) {
    throw Error(err);
  }
});

export const getTemplate = createAsyncThunk('getTemplate', async ({ templateUid }) => {
  try {
    const result = await httpClient.getRecordTemplate(templateUid);
    return result;
  } catch (err) {
    throw Error(err);
  }
});

export const getMarketCompTotalCost = createAsyncThunk('getMarketCompTotalCost', async ({ comps, standardFieldUOM, escalation }) => {
  try {
    const [result] = await httpClient.getMarketCompUnitCosts(comps, standardFieldUOM, escalation, true);
    return result;
  } catch (err) {
    throw Error(err);
  }
});

export const saveConfig = createAsyncThunk('saveConfig', async ({ recordUid, configType, config }) => {
  try {
    await httpClient.saveEstimateConfig(recordUid, configType, config);
  } catch (err) {
    throw Error(err);
  }
});

export const getConfig = createAsyncThunk('getConfig', async ({ recordUid, consolidatedRecordUid }, helpers) => {
  try {
    const [result] = await httpClient.loadEstimateConfig(recordUid);

    // Deep clone the config object for changes below
    let config = JSON.parse(JSON.stringify(JSON.parse(result.estimate_config) || initialEstimateConfig));

    const record = helpers.getState().estimate.records.data[recordUid];
    const isConsolidated = recordUid === consolidatedRecordUid && record.estimate_children?.length > 0;

    let fieldCalculations = JSON.parse(result.field_calculations || '{}');
    fieldCalculations = convertOldFieldCalculations(fieldCalculations);
    const bucketCalculations = JSON.parse(result.bucket_calculations || '{}');
    const bucketGroupCalculations = JSON.parse(result.bucket_group_calculations || '{}');
    const fieldGroupCalculations = JSON.parse(result.field_group_calculations || '{}');
    const manualItems = JSON.parse(result.manual_items) || initialManualItems;

    let selectedRecCostFields = {};
    let detailedEstimates = {};
    let consolidatedRecordsConfigs = {};
    let consolidatedSoftCosts = [];
    let isConsolidatedTemplatesValid = false;

    if (!isConsolidated) {
      selectedRecCostFields = JSON.parse(result.selected_rec_cost_fields || '{}');
      detailedEstimates = JSON.parse(result.detailed_estimates || '{}');
    } else {
      let consolidatedEstimateFormats = [];
      // Get all of the child estimates if its consolidated
      for (const childUid of record.estimate_children) {
        const [childEstimateConfig] = await httpClient.loadEstimateConfig(childUid);
        const estimateConfig = JSON.parse(childEstimateConfig.estimate_config || '{}');
        const selectedEstimateTemplate = estimateConfig?.compSetting?.root?.selectedEstimateTemplate || '';
        consolidatedEstimateFormats.push(selectedEstimateTemplate);

        consolidatedRecordsConfigs[childUid] = {
          ...childEstimateConfig,
          // Might as well JSON.parse these here, to avoid having to do it multiple times in the app
          estimate_config: estimateConfig,
          field_calculations: JSON.parse(childEstimateConfig.field_calculations || '{}'),
          estimate_record_buckets: JSON.parse(childEstimateConfig.estimate_record_buckets || '{}'),
          manual_items: JSON.parse(childEstimateConfig.manual_items) || initialManualItems
        };
      }

      if (consolidatedEstimateFormats.length > 0 && consolidatedEstimateFormats[0] !== '') {
        // Check if all child templates are the same
        isConsolidatedTemplatesValid = consolidatedEstimateFormats.every((format) => format === consolidatedEstimateFormats[0]);
        // Set the estimate format to the first child
        config.compSetting.root.selectedEstimateTemplate = consolidatedEstimateFormats[0];
      }

      // Get all the consolidated soft costs
      // We only need to do this once since the child records wont change while on the consolidated estimate
      const consolidatedSoftCostsResult = await httpClient.getEstimateConsolidatedSoftCosts(record.estimate_children);
      if (consolidatedSoftCostsResult) {
        consolidatedSoftCosts = consolidatedSoftCostsResult;
      }
    }

    // If there is no escalation data on the estimate, fetch it from the company settings
    if (!config.costSetting.root.escalationPerYear || !Object.keys(config.costSetting.root.escalationPerYear).length) {
      const escalationRates = await httpClient.getCompanyEscalationRates();
      config.costSetting.root.escalationPerYear = escalationRates;
    }

    // Normalizing some old data
    // We should do this in the DB, but it works here for now
    config = convertOldConfigData(config, isConsolidated);
    detailedEstimates = convertOldDetailedEstimates(detailedEstimates);

    // We can set the estimateDesignValues here by default
    // It avoids having to do it on the first render
    const estimateDesignValuesConfig = {
      manualItems,
      estimateConfig: config,
      isConsolidated,
      consolidatedRecordsConfigs
    };
    let estimateDesignValues = {};
    Object.keys(designFields).forEach((designFieldUid) => {
      estimateDesignValues[designFields[designFieldUid]] = getEstimateDesignValue(estimateDesignValuesConfig, designFields[designFieldUid]);
    });

    // fetch all of the templates here since we only need to do it once
    const templates = await httpClient.getRecordTemplates();
    let allTemplates = {};
    templates.forEach((template) => {
      allTemplates[template.uid] = template;
    });

    return {
      config,
      fieldCalculations,
      bucketCalculations,
      bucketGroupCalculations,
      fieldGroupCalculations,
      detailedEstimates,
      manualItems,
      selectedRecCostFields,
      isConsolidated,
      consolidatedRecordsConfigs,
      consolidatedSoftCosts,
      isConsolidatedTemplatesValid,
      templates: allTemplates,
      estimateDesignValues
    };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    window.onerror(err);
    throw err;
  }
});

// The purpose of this function is to ensure all calculations have the correct data structure, and to add any custom fields that need added
export const initializeAllCalculations = createAsyncThunk('initializeAllCalculations', async (projectType, helpers) => {
  try {
    const estimate = helpers.getState().estimate;

    let customDesignFields = { ...estimate.config.data.programming.customDesignFields };
    let designRecordFields = estimate.designRecordFields.data;
    const selectedEstimateTemplate = estimate.templates[estimate.config.data.compSetting.root.selectedEstimateTemplate];
    // fields
    const fields = estimate.fields.data;
    let fieldCalculations = { ...estimate.fieldCalculations };
    let fieldCalculationTotals = {};
    // detailed estimates
    let detailedEstimates = { ...estimate.detailedEstimates };
    let detailedEstimateTotals = {};
    // buckets
    const buckets = estimate.buckets.data;
    let bucketCalculations = { ...estimate.bucketCalculations };
    // bucket groups
    const bucketGroups = estimate.buckets.groups;
    let bucketGroupCalculations = { ...estimate.bucketGroupCalculations };
    // field groups
    const fieldGroups = estimate.fields.groups;
    let fieldGroupCalculations = { ...estimate.fieldGroupCalculations };

    const selectedTemplate = estimate.templates[estimate.config.data.compSetting.root.selectedEstimateTemplate];
    const isMultifamily = isMultifamilyOrEquivalent(selectedTemplate.sector_type);
    const estimateProgrammingConfig = estimate.config.data.programming.estimateProgrammingConfig;
    const estimateProgrammingConfigFieldUids = getFieldsFromEstimateProgrammingConfig(estimateProgrammingConfig);

    let defaultUom = 'd8b2d78a40024fad80060823e7d1fc28';
    let defaultUomType = 'standard';
    let defaultTotalCostUom = '22539d6e2857419ea6e10927d74de3f5';
    let defaultTotalCostUomType = 'standard';

    if (!isMultifamilyOrEquivalent(selectedTemplate.sector_type)) {
      defaultUom = estimateProgrammingConfigFieldUids[0];
      defaultUomType = 'custom_programming_field';
      defaultTotalCostUom = estimateProgrammingConfigFieldUids[0];
      defaultTotalCostUomType = 'custom_programming_field';
    }

    const addCustomDesignField = (uom) => {
      let allFields = {};
      Object.keys(designRecordFields).forEach((designRecordFieldBucket) => {
        allFields = { ...allFields, ...designRecordFields[designRecordFieldBucket].fields };
      });

      const fieldToAdd = allFields[uom];
      if (fieldToAdd) {
        customDesignFields[uom] = {
          field_name: fieldToAdd.field_name,
          standard_field_uid: fieldToAdd.standard_field || null,
          uid: uom,
          unit: '',
          value: customDesignFields[uom]?.value || 0,
          is_from_template: true
        };
      }
    };

    const bucketUids = Object.keys(buckets);

    // 1. Loop over the buckets and initialize them
    bucketUids.forEach((bucketUid) => {
      const bucket = buckets[bucketUid];
      const bucketCalculation = { ...bucketCalculations[bucketUid] };

      // ensure there is a uom set for the bucket calculation
      if (!isMultifamily) {
        if (!bucketCalculation.selected_uom) {
          if (estimateProgrammingConfigFieldUids.includes(bucket.meta.uom_field_uid)) {
            bucketCalculation.selected_uom = bucket.meta.uom_field_uid;
            bucketCalculation.selected_uom_type = 'custom_programming_field';
          } else if (!bucket.meta.default_uom || !standardFields[bucket.meta.default_uom]) {
            bucketCalculation.selected_uom = bucket.meta.uom_field_uid;
            bucketCalculation.selected_uom_type = 'custom_design_field';
          } else {
            bucketCalculation.selected_uom = bucket.meta.default_uom;
            bucketCalculation.selected_uom_type = 'standard';
          }
        }
      } else {
        if (!bucketCalculation.selected_uom) {
          if (!bucket.meta.default_uom || !standardFields[bucket.meta.default_uom]) {
            bucketCalculation.selected_uom = bucket.meta.uom_field_uid;
            bucketCalculation.selected_uom_type = 'custom_design_field';
          } else {
            bucketCalculation.selected_uom = bucket.meta.default_uom;
            bucketCalculation.selected_uom_type = 'standard';
          }
        }
      }

      // ensure the custom field is added to the config if it doesn't exist
      if (bucketCalculation.selected_uom_type === 'custom_design_field') {
        addCustomDesignField(bucketCalculation.selected_uom);
      }

      bucketCalculations[bucketUid] = bucketCalculation;
    });

    // 2, Loop over the fields and initialize them
    Object.keys(fields)
      .filter((fieldUid) => {
        // Sometimes we have some weird data for fields, so we can check here that the bucket is valid
        const field = fields[fieldUid];
        return bucketUids.includes(field.meta.et_field_bucket);
      })
      .forEach((fieldUid) => {
        const field = fields[fieldUid];

        const fieldCalculation = {
          ...fieldCalculations[fieldUid],
          unitCost: { ...fieldCalculations[fieldUid]?.unitCost },
          totalCost: { ...fieldCalculations[fieldUid]?.totalCost },
          quantity: { ...fieldCalculations[fieldUid]?.quantity }
        };

        // ensure there is a uom set for the field calculation
        if (!isMultifamily) {
          if (!fieldCalculation.selected_uom) {
            if (estimateProgrammingConfigFieldUids.includes(field.meta.uom_field_uid)) {
              fieldCalculation.selected_uom = field.meta.uom_field_uid;
              fieldCalculation.selected_uom_type = 'custom_programming_field';
            } else if (!field.meta.default_uom || !standardFields[field.meta.default_uom]) {
              fieldCalculation.selected_uom = field.meta.uom_field_uid;
              fieldCalculation.selected_uom_type = 'custom_design_field';
            } else {
              fieldCalculation.selected_uom = field.meta.default_uom;
              fieldCalculation.selected_uom_type = 'standard';
            }
          }
        } else {
          if (!fieldCalculation.selected_uom) {
            if (!field.meta.default_uom || !standardFields[field.meta.default_uom]) {
              fieldCalculation.selected_uom = field.meta.uom_field_uid;
              fieldCalculation.selected_uom_type = 'custom_design_field';
            } else {
              fieldCalculation.selected_uom = field.meta.default_uom;
              fieldCalculation.selected_uom_type = 'standard';
            }
          }
        }

        // ensure the custom field is added to the config if it doesn't exist
        if (fieldCalculation.selected_uom_type === 'custom_design_field') {
          addCustomDesignField(fieldCalculation.selected_uom);
        }

        fieldCalculationTotals[fieldUid] = { total: 0 };
        fieldCalculations[fieldUid] = fieldCalculation;
      });

    // 3. Loop over field group calculations and initialize them
    Object.keys(buckets).forEach((bucketUid) => {
      const bucket = buckets[bucketUid];

      if (fieldGroups[bucketUid]) {
        const bucketFieldGroups = Object.keys(fieldGroups[bucketUid]);
        bucketFieldGroups.forEach((bucketFieldGroup) => {
          const fieldGroupCalculation = { ...fieldGroupCalculations[`${bucketUid}-${bucketFieldGroup}`] };

          // ensure there is a uom set for the field group calculation
          if (!fieldGroupCalculation.selected_uom) {
            const bucketMetadata = bucket.meta.et_bucket_metadata;
            // Its possible the template doesn't have any metadata
            // todo: ensure this isn't possible
            if (!bucketMetadata) {
              fieldGroupCalculation.selected_uom = defaultUom;
              fieldGroupCalculation.selected_uom_type = defaultUomType;
            } else {
              const groupUoms = JSON.parse(bucketMetadata).group_uoms || {};
              const fieldGroupUom = groupUoms[bucketFieldGroup];
              if (!fieldGroupUom) {
                fieldGroupCalculation.selected_uom = defaultUom;
                fieldGroupCalculation.selected_uom_type = defaultUomType;
              } else {
                let selectedDesignField;

                Object.keys(designRecordFields).forEach((designRecordFieldBucket) => {
                  const fields = designRecordFields[designRecordFieldBucket].fields;
                  const field = Object.keys(fields).find((designFieldUid) => designFieldUid === fieldGroupUom);
                  if (field) {
                    selectedDesignField = fields[field];
                  }
                });

                if (!isMultifamily) {
                  if (selectedDesignField) {
                    if (estimateProgrammingConfigFieldUids.includes(selectedDesignField.uid)) {
                      fieldGroupCalculation.selected_uom = selectedDesignField.uid;
                      fieldGroupCalculation.selected_uom_type = 'custom_programming_field';
                    } else if (!selectedDesignField.standard_field || !standardFields[selectedDesignField.standard_field]) {
                      fieldGroupCalculation.selected_uom = selectedDesignField.uid;
                      fieldGroupCalculation.selected_uom_type = 'custom_design_field';
                    } else {
                      fieldGroupCalculation.selected_uom = selectedDesignField.standard_field;
                      fieldGroupCalculation.selected_uom_type = 'standard';
                    }
                  } else {
                    fieldGroupCalculation.selected_uom = defaultUom;
                    fieldGroupCalculation.selected_uom_type = defaultUomType;
                  }
                } else {
                  if (selectedDesignField) {
                    if (!selectedDesignField.standard_field || !standardFields[selectedDesignField.standard_field]) {
                      fieldGroupCalculation.selected_uom = selectedDesignField.uid;
                      fieldGroupCalculation.selected_uom_type = 'custom_design_field';
                    } else {
                      fieldGroupCalculation.selected_uom = selectedDesignField.standard_field;
                      fieldGroupCalculation.selected_uom_type = 'standard';
                    }
                  } else {
                    fieldGroupCalculation.selected_uom = defaultUom;
                    fieldGroupCalculation.selected_uom_type = defaultUomType;
                  }
                }
              }
            }
          }

          // ensure the custom field is added to the config if it doesn't exist
          if (fieldGroupCalculation.selected_uom_type === 'custom_design_field') {
            const customDesignField = customDesignFields[fieldGroupCalculation.selected_uom];
            if (!customDesignField) {
              addCustomDesignField(fieldGroupCalculation.selected_uom);
            }
          }

          fieldGroupCalculations[`${bucketUid}-${bucketFieldGroup}`] = fieldGroupCalculation;
        });
      }
    });

    // 4. Loop over bucket group calculations and initialize them
    Object.keys(bucketGroups).forEach((bucketGroupUid) => {
      const bucketGroupCalculation = { ...bucketGroupCalculations[bucketGroupUid] };

      // ensure there is a uom set for the bucket calculation
      if (!bucketGroupCalculation.selected_uom) {
        const templateMetadata = selectedEstimateTemplate.metadata;
        // Its possible the template doesn't have any metadata
        if (!templateMetadata) {
          bucketGroupCalculation.selected_uom = defaultUom;
          bucketGroupCalculation.selected_uom_type = defaultUomType;
        } else {
          const groupUoms = JSON.parse(templateMetadata).group_uoms || {};
          const bucketGroupUom = groupUoms[bucketGroupUid];

          if (!bucketGroupUom) {
            bucketGroupCalculation.selected_uom = defaultUom;
            bucketGroupCalculation.selected_uom_type = defaultUomType;
          } else {
            let selectedDesignField;

            Object.keys(designRecordFields).forEach((designRecordFieldBucket) => {
              const fields = designRecordFields[designRecordFieldBucket].fields;
              const field = Object.keys(fields).find((designFieldUid) => designFieldUid === bucketGroupUom);
              if (field) {
                selectedDesignField = fields[field];
              }
            });

            if (!isMultifamily) {
              if (selectedDesignField) {
                if (estimateProgrammingConfigFieldUids.includes(selectedDesignField.uid)) {
                  bucketGroupCalculation.selected_uom = selectedDesignField.uid;
                  bucketGroupCalculation.selected_uom_type = 'custom_programming_field';
                } else if (!selectedDesignField.standard_field || !standardFields[selectedDesignField.standard_field]) {
                  bucketGroupCalculation.selected_uom = selectedDesignField.uid;
                  bucketGroupCalculation.selected_uom_type = 'custom_design_field';
                } else {
                  bucketGroupCalculation.selected_uom = selectedDesignField.standard_field;
                  bucketGroupCalculation.selected_uom_type = 'standard';
                }
              } else {
                bucketGroupCalculation.selected_uom = defaultUom;
                bucketGroupCalculation.selected_uom_type = defaultUomType;
              }
            } else {
              if (selectedDesignField) {
                if (!selectedDesignField.standard_field || !standardFields[selectedDesignField.standard_field]) {
                  bucketGroupCalculation.selected_uom = selectedDesignField.uid;
                  bucketGroupCalculation.selected_uom_type = 'custom_design_field';
                } else {
                  bucketGroupCalculation.selected_uom = selectedDesignField.standard_field;
                  bucketGroupCalculation.selected_uom_type = 'standard';
                }
              } else {
                bucketGroupCalculation.selected_uom = defaultUom;
                bucketGroupCalculation.selected_uom_type = defaultUomType;
              }
            }
          }
        }
      }

      // ensure the custom field is added to the config if it doesn't exist
      if (bucketGroupCalculation.selected_uom_type === 'custom_design_field') {
        const customDesignField = customDesignFields[bucketGroupCalculation.selected_uom];
        if (!customDesignField) {
          addCustomDesignField(bucketGroupCalculation.selected_uom);
        }
      }

      bucketGroupCalculations[bucketGroupUid] = bucketGroupCalculation;
    });

    // 5. Loop over the detailed estimates and initalize them
    Object.keys(detailedEstimates).forEach((fieldUid) => {
      const detailedEstimate = detailedEstimates[fieldUid];
      Object.keys(detailedEstimate.subFields).forEach((subFieldUid) => {
        detailedEstimateTotals[subFieldUid] = { total: 0 };
      });
    });

    return {
      fieldCalculations,
      fieldCalculationTotals,
      detailedEstimateTotals,
      bucketCalculations,
      fieldGroupCalculations,
      bucketGroupCalculations,
      customDesignFields,
      defaultTotalCostUom,
      defaultTotalCostUomType
    };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    window.onerror(err);
    throw err;
  }
});

export const calculateAllCalculations = createAsyncThunk('calculateAllCalculations', async (_, helpers) => {
  try {
    const estimate = helpers.getState().estimate;
    let fieldCalculationTotals = {};
    let detailedEstimateTotals = {};
    const detailedEstimates = estimate.detailedEstimates;
    // 1. calculate detailed estimates if its not consolidated or 16 division
    if (!estimate.isConsolidated && estimate.config.data.compSetting.root.selectedEstimateTemplate !== '2e051d1319f7465893ebecc52516bdcd') {
      Object.keys(detailedEstimates).forEach((fieldUid) => {
        const detailedEstimate = detailedEstimates[fieldUid];
        Object.keys(detailedEstimate.subFields).forEach((subFieldUid) => {
          detailedEstimateTotals[subFieldUid] = getDetailedEstimateSubFieldCost(subFieldUid, fieldUid, estimate);
        });
      });
    }

    let totalDirectCost = 0;

    // 2. calculate field calculations
    Object.keys(estimate.fieldCalculationTotals).forEach((fieldUid) => {
      const fieldCost = getFieldCost(fieldUid, estimate, detailedEstimateTotals);
      fieldCalculationTotals[fieldUid] = fieldCost;
      totalDirectCost += fieldCost.total;
    });

    return {
      fieldCalculationTotals,
      detailedEstimateTotals,
      totalDirectCost
    };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    window.onerror(err);
    throw err;
  }
});

export const calculateAllIndirectCosts = createAsyncThunk('calculateAllIndirectCosts', async (_, helpers) => {
  try {
    const estimate = helpers.getState().estimate;
    const indirectCosts = estimate.config.data.costSetting.root.indirectCosts;

    let totalIndirectCost = 0;
    const indirectCostTotals = {};

    if (estimate.isConsolidated) {
      estimate.consolidatedSoftCosts.forEach((consolidatedSoftCost) => {
        totalIndirectCost += consolidatedSoftCost.consolidated_cost || 0;
      });
    } else {
      const indirectCostUids = Object.keys(indirectCosts);
      indirectCostUids.forEach((indirectCostUid) => {
        const cost = getIndirectCost(indirectCostUid, indirectCostTotals, estimate);
        totalIndirectCost += cost;
        indirectCostTotals[indirectCostUid] = cost;
      });
    }
    return {
      indirectCostTotals,
      totalIndirectCost
    };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    window.onerror(err);
    throw err;
  }
});

export const setCompDesignValues = createAsyncThunk('setCompDesignValues', async (_, helpers) => {
  try {
    const state = helpers.getState().estimate;
    const fieldCalculationTotals = state.fieldCalculationTotals;
    const fieldUids = Object.keys(fieldCalculationTotals);
    const buckets = state.buckets.data;
    const bucketUids = Object.keys(buckets);
    let fieldCompDesignValues = {};
    let bucketCompDesignValues = {};
    let fieldErrors = {};

    fieldUids.forEach((fieldUid) => {
      const compValues = getFieldCompDesignValuesAndErrors(fieldUid, state);
      fieldCompDesignValues[fieldUid] = compValues.fieldCompDesignValues;
      fieldErrors[fieldUid] = compValues.fieldErrors;
    });

    bucketUids.forEach((bucketUid) => {
      bucketCompDesignValues[bucketUid] = getBucketCompDesignValues(bucketUid, state);
    });

    return { fieldCompDesignValues, bucketCompDesignValues, fieldErrors };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    window.onerror(err);
    throw err;
  }
});

export const setCustomDesignField = createAsyncThunk('setCustomDesignField', async ({ customDesignFieldUid }, helpers) => {
  try {
    const estimate = helpers.getState().estimate;
    const updatedFieldCalculationTotals = { ...estimate.fieldCalculationTotals };
    const updatedDetailedEstimateTotals = { ...estimate.detailedEstimateTotals };
    let totalDirectCost = 0;

    for (const fieldUid in estimate.fieldCalculationTotals) {
      // eslint-disable-next-line no-prototype-builtins
      if (estimate.fieldCalculationTotals.hasOwnProperty(fieldUid)) {
        const fieldCalculation = estimate.fieldCalculations[fieldUid];
        const detailedEstimate = estimate.detailedEstimates[fieldUid];

        if (detailedEstimate) {
          for (const subFieldUid in detailedEstimate.subFields) {
            // eslint-disable-next-line no-prototype-builtins
            if (detailedEstimate.subFields.hasOwnProperty(subFieldUid)) {
              const subField = detailedEstimate.subFields[subFieldUid];
              if (subField.selectedUom === customDesignFieldUid) {
                updatedDetailedEstimateTotals[subFieldUid] = getDetailedEstimateSubFieldCost(subFieldUid, fieldUid, estimate);
              }
            }
          }
        }

        if (fieldCalculation.selected_uom === customDesignFieldUid) {
          updatedFieldCalculationTotals[fieldUid] = getFieldCost(fieldUid, estimate, updatedDetailedEstimateTotals);
        }
        totalDirectCost += updatedFieldCalculationTotals[fieldUid].total;
      }
    }

    return {
      fieldCalculationTotals: updatedFieldCalculationTotals,
      detailedEstimateTotals: updatedDetailedEstimateTotals,
      totalDirectCost
    };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    window.onerror(err);
    throw err;
  }
});

export const setEstimateDesignValues = createAsyncThunk('setEstimateDesignValues', async ({ estimateDesignValues }, helpers) => {
  try {
    const updatedFieldUoms = new Set(Object.keys(estimateDesignValues));
    const estimate = helpers.getState().estimate;
    const updatedFieldCalculationTotals = { ...estimate.fieldCalculationTotals };
    const updatedDetailedEstimateTotals = { ...estimate.detailedEstimateTotals };
    let totalDirectCost = 0;

    for (const fieldUid of Object.keys(estimate.fieldCalculationTotals)) {
      const fieldCalculation = estimate.fieldCalculations[fieldUid];
      const detailedEstimate = estimate.detailedEstimates[fieldUid];

      if (detailedEstimate) {
        for (const subFieldUid of Object.keys(detailedEstimate.subFields)) {
          const subField = detailedEstimate.subFields[subFieldUid];
          if (updatedFieldUoms.has(subField.selectedUom)) {
            updatedDetailedEstimateTotals[subFieldUid] = getDetailedEstimateSubFieldCost(subFieldUid, fieldUid, estimate);
          }
        }
      }

      if (updatedFieldUoms.has(fieldCalculation.selected_uom)) {
        updatedFieldCalculationTotals[fieldUid] = getFieldCost(fieldUid, estimate, updatedDetailedEstimateTotals);
      }
      totalDirectCost += updatedFieldCalculationTotals[fieldUid].total;
    }

    return {
      fieldCalculationTotals: updatedFieldCalculationTotals,
      detailedEstimateTotals: updatedDetailedEstimateTotals,
      totalDirectCost
    };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    window.onerror(err);
    throw err;
  }
});

let saveInProgress = {};

/// This is ... alot
// At some point we need to break this down into smaller chunks instead of a full delete/create
export const saveBucketsAndFields = createAsyncThunk('saveBucketsAndFields', async ({ recordUid }, helpers) => {
  try {
    const estimate = helpers.getState().estimate;

    if (saveInProgress[recordUid]) {
      return;
    }

    saveInProgress[recordUid] = true;

    const bucketUids = Object.keys(estimate.buckets.data);
    const customMetricsUids = Object.keys(estimate.config.data.programming.customDesignFields);
    const isMultifamily = isMultifamilyOrEquivalent(estimate.templates[estimate.config.data.compSetting.root.selectedEstimateTemplate].sector_type);

    const deleteAllBuckets = async () => {
      const deleteBucketResult = await httpClient.deleteAllBuckets(recordUid);

      if (!deleteBucketResult) {
        throw Error('Unable to delete buckets');
      }

      return deleteBucketResult;
    };

    const saveDesignBuckets = async () => {
      // 2. Create design fields bucket
      const designBucketsResult = await httpClient.createBucket(recordUid, {
        bucket_name: 'Estimate Design Inputs',
        description: 'Input parameters entered to calculate the cost estimate of the new project.',
        category: 'Design',
        bucket_code: '00',
        hard_cost: false,
        default_comp_field: null
      });

      if (!designBucketsResult) {
        throw Error('Unable to create design fields bucket');
      }

      return designBucketsResult;
    };

    const saveCustomMetricsBucket = async () => {
      if (customMetricsUids.length > 0) {
        const customMetricsBucketResult = await httpClient.createBucket(recordUid, {
          bucket_name: 'Custom Metrics',
          description: 'Custom metrics entered to calculate the cost estimate of the new project.',
          category: 'Design',
          bucket_code: '02',
          hard_cost: false,
          default_comp_field: null
        });

        if (!customMetricsBucketResult) {
          throw Error('Unable to create custom metrics bucket');
        }

        return customMetricsBucketResult;
      }
    };

    const saveIndirectCostsBucket = async () => {
      const indirectCostsResult = await httpClient.createBucket(recordUid, {
        bucket_name: 'Indirect Costs',
        description: '',
        category: 'Cost',
        bucket_code: '99',
        hard_cost: false,
        default_comp_field: null
      });

      if (!indirectCostsResult) {
        throw Error('Unable to create indirect costs bucket');
      }

      return indirectCostsResult;
    };

    const saveBuckets = async () => {
      const newBuckets = bucketUids.map((bucketUid) => {
        const bucket = estimate.buckets.data[bucketUid];
        return {
          recordUid: recordUid,
          estTemplateBucketUid: bucket.meta.et_bucket_uid,
          bucket_name: bucket.meta.et_bucket_name,
          description: '',
          category: 'Cost',
          bucket_code: bucket.meta.et_bucket_code || '',
          hard_cost: true,
          metadata: bucket.meta.et_bucket_metadata
        };
      });
      const bucketsResult = await httpClient.createBuckets(recordUid, newBuckets);

      if (!bucketsResult) {
        throw Error('Unable to create buckets');
      }

      return bucketsResult;
    };

    const saveDesignFields = async (designBucketsResult) => {
      let newDesignFields = [];

      if (isMultifamily) {
        newDesignFields = stdDesignFields().map((designField) => ({
          record_uid: recordUid,
          is_demo: false,
          is_standard_field: false,
          field_category: 'Design',
          bucket_uid: designBucketsResult.bucket_uid,
          division_uid: null,
          field_name: designField.field_name,
          field_description: '',
          value_type: 'Text',
          value: estimate.estimateDesignValues[designField.uid]?.toString(),
          code: '',
          default_comp_field: null,
          standard_field: designField.uid || null
        }));
      } else {
        const fields = getFieldsFromEstimateProgrammingConfig(estimate.config.data.programming.estimateProgrammingConfig);
        newDesignFields = fields
          .filter((designFieldUid) => {
            const designField = estimate.designRecordFields.fields[designFieldUid];
            return !!designField;
          })
          .map((designFieldUid) => {
            const designField = estimate.designRecordFields.fields[designFieldUid];
            return {
              record_uid: recordUid,
              is_demo: false,
              is_standard_field: false,
              field_category: 'Design',
              bucket_uid: designBucketsResult.bucket_uid,
              division_uid: null,
              field_name: designField.field_name,
              field_description: '',
              value_type: designField.value_type || 'Text',
              value_unit_uid: designField.value_unit_uid,
              value: estimate.estimateDesignValues[designField.uid]?.toString(),
              code: '',
              default_comp_field: null,
              standard_field: designField.standard_field || null
            };
          });
      }

      const designFieldResult = await httpClient.createRecordFieldsBulk(newDesignFields, recordUid);

      if (!designFieldResult) {
        throw Error('Unable to create design fields');
      }

      return designFieldResult;
    };

    const saveCustomMetricsFields = async (customMetricsBucketResult) => {
      if (customMetricsUids.length > 0) {
        const newCustomMetrics = customMetricsUids.map((customMetricsUid) => {
          const customDesignField = estimate.config.data.programming.customDesignFields[customMetricsUid];
          return {
            record_uid: recordUid,
            is_demo: false,
            is_standard_field: false,
            field_category: 'Design',
            bucket_uid: customMetricsBucketResult.bucket_uid,
            division_uid: null,
            field_name: customDesignField.field_name,
            field_description: '',
            value_type: 'Text',
            value_unit_uid: customDesignField.value_unit_uid,
            value: customDesignField.value?.toString(),
            code: '',
            default_comp_field: null,
            standard_field: customDesignField.standard_field_uid
          };
        });

        const customMetricsFieldResult = await httpClient.createRecordFieldsBulk(newCustomMetrics, recordUid);

        if (!customMetricsFieldResult) {
          throw Error('Unable to create custom metrics field');
        }

        return customMetricsFieldResult;
      }
    };

    const saveIndirectCostFields = async (indirectCostsBucketsResult) => {
      let newIndirectCosts;
      if (estimate.isConsolidated) {
        newIndirectCosts = estimate.consolidatedSoftCosts.map((consolidatedSoftCost) => ({
          record_uid: recordUid,
          is_demo: false,
          is_standard_field: false,
          field_category: 'Cost',
          bucket_uid: indirectCostsBucketsResult.bucket_uid,
          division_uid: null,
          field_name: consolidatedSoftCost.field_name,
          field_description: '',
          value_type: 'Float',
          value: consolidatedSoftCost.consolidated_cost?.toString(),
          code: consolidatedSoftCost.code?.toString(),
          default_comp_field: null,
          standard_field: null
        }));
      } else {
        const indirectCostUids = Object.keys(estimate.config.data.costSetting.root.indirectCosts);
        newIndirectCosts = indirectCostUids.map((indirectCostsUid) => {
          const indirectCost = estimate.config.data.costSetting.root.indirectCosts[indirectCostsUid];
          return {
            record_uid: recordUid,
            is_demo: false,
            is_standard_field: false,
            field_category: 'Cost',
            bucket_uid: indirectCostsBucketsResult.bucket_uid,
            division_uid: null,
            field_name: indirectCost.label,
            field_description: '',
            value_type: 'Float',
            value: estimate.indirectCostTotals[indirectCostsUid]?.toFixed(2)?.toString() || '0',
            code: indirectCost.code?.toString(),
            default_comp_field: null,
            standard_field: null
          };
        });
      }

      const indirectCostsFieldsResult = await httpClient.createRecordFieldsBulk(newIndirectCosts, recordUid);

      if (!indirectCostsFieldsResult) {
        throw Error('Unable to create indirect cost fields');
      }

      return indirectCostsFieldsResult;
    };

    const saveFields = async (bucketsResult, designBucketsResult) => {
      const newFields = [];

      for (const bucketUid of bucketUids) {
        const fieldUids = Object.keys(estimate.fields.data).filter((fieldUid) => estimate.fields.data[fieldUid].meta.et_field_bucket === bucketUid);

        for (const fieldUid of fieldUids) {
          const field = estimate.fields.data[fieldUid];
          const fieldCost = String(estimate.fieldCalculationTotals[fieldUid].total || 0);
          let detailedBudget;

          if (estimate.detailedEstimates[fieldUid]) {
            detailedBudget = {
              ...estimate.detailedEstimates[fieldUid]
            };

            detailedBudget.sortOrder.forEach((subFieldUid) => {
              detailedBudget.subFields = { ...detailedBudget.subFields };
              detailedBudget.subFields[subFieldUid] = {
                ...detailedBudget.subFields[subFieldUid],
                totalCost: String(estimate.detailedEstimateTotals[subFieldUid].total || 0),
                quantity: String(estimate.detailedEstimateTotals[subFieldUid].quantity || 0)
              };
            });
          }

          newFields.push({
            record_uid: recordUid,
            is_demo: false,
            is_standard_field: false,
            field_category: 'Cost',
            bucket_uid: bucketsResult.data[bucketUid],
            division_uid: null,
            field_name: field.meta.et_field_name,
            field_description: '',
            value_type: 'Float',
            value: fieldCost,
            code: field.meta.et_field_code,
            default_comp_field: designBucketsResult[field.meta.default_uom] || null,
            standard_field: field.meta.et_std_field,
            detail_estimate: detailedBudget,
            metadata: field.meta?.et_field_metadata
          });
        }
      }

      const fieldsResult = await httpClient.createRecordFieldsSuperBulk(newFields, recordUid);

      if (!fieldsResult) {
        throw new Error('Unable to create fields');
      }

      return fieldsResult;
    };

    // 1. delete all the buckets
    await deleteAllBuckets(); //

    // 2. Create buckets
    const [designBucketsResult, customMetricsBucketResult, indirectCostsBucketsResult, bucketsResult] = await Promise.all([
      saveDesignBuckets(),
      saveCustomMetricsBucket(),
      saveIndirectCostsBucket(),
      saveBuckets()
    ]);

    // 3. Create fields
    await Promise.all([
      saveDesignFields(designBucketsResult),
      saveCustomMetricsFields(customMetricsBucketResult),
      saveIndirectCostFields(indirectCostsBucketsResult),
      saveFields(bucketsResult, designBucketsResult)
    ]);

    saveInProgress[recordUid] = false;
  } catch (err) {
    saveInProgress[recordUid] = false;
    // eslint-disable-next-line no-console
    console.log(err);
    window.onerror(err);
    throw err;
  }
});

export const estimateSlice = createSlice({
  name: 'estimate',
  initialState,
  reducers: {
    resetEstimate: () => {
      return initialState;
    },
    setPrimaryStep: (state, action) => {
      state.primaryStep = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setIsAdjustmentColumnVisible: (state, action) => {
      state.isAdjustmentColumnVisible = action.payload;
    },
    setIsNotesColumnVisible: (state, action) => {
      state.isNotesColumnVisible = action.payload;
    },
    setIsOverviewMinimized: (state, action) => {
      state.isOverviewMinimized = action.payload;
    },
    setIsFullScreen: (state, action) => {
      state.isFullScreen = action.payload;
    },
    setEffectiveEscalation: (state, action) => {
      state.effectiveEscalation = action.payload;
    },
    setIsDeleteEstimateOpen: (state, action) => {
      state.deleteEstimate.isOpen = action.payload;
    },
    setIsRenameEstimateOpen: (state, action) => {
      state.renameEstimate.isOpen = action.payload;
    },
    setIsSaving: (state, action) => {
      state.isSaving = action.payload;
    },
    updateRecord: (state, action) => {
      state.records.data[action.payload.recordUid][action.payload.key] = action.payload.value;
    },
    recalculateFieldCalculation: (state, action) => {
      state.fieldCalculationTotals[action.payload.fieldUid] = getFieldCost(action.payload.fieldUid, state, state.detailedEstimateTotals);
      state.totalDirectCost = getTotalDirectCost(state);
      const newIndirectCosts = getTotalIndirectCosts(state);
      state.indirectCostTotals = newIndirectCosts.indirectCosts;
      state.totalIndirectCost = newIndirectCosts.totalIndirectCost;
    },
    setFieldCalculation: (state, action) => {
      state.fieldCalculations[action.payload.fieldUid][action.payload.key] = action.payload.value;

      if (action.payload.key === 'selected_uom') {
        let uomType = 'standard';
        if (state.config.data.programming.customDesignFields[action.payload.value]) {
          uomType = 'custom_design_field';
        } else if (action.payload.value === 'custom_value') {
          uomType = 'custom_value';
        } else if (state.designRecordFields.fields[action.payload.value]) {
          uomType = 'custom_programming_field';
        }
        state.fieldCalculations[action.payload.fieldUid].selected_uom_type = uomType;

        const compValues = getFieldCompDesignValuesAndErrors(action.payload.fieldUid, state);
        state.fieldCompDesignValues[action.payload.fieldUid] = compValues.fieldCompDesignValues;
        state.fieldErrors[action.payload.fieldUid] = compValues.fieldErrors;
      }

      if (action.payload.recalculate) {
        state.fieldCalculationTotals[action.payload.fieldUid] = getFieldCost(action.payload.fieldUid, state, state.detailedEstimateTotals);
        state.totalDirectCost = getTotalDirectCost(state);
        const newIndirectCosts = getTotalIndirectCosts(state);
        state.indirectCostTotals = newIndirectCosts.indirectCosts;
        state.totalIndirectCost = newIndirectCosts.totalIndirectCost;
      }
    },
    setFieldCalculationUom: (state, action) => {
      state.fieldCalculations[action.payload.fieldUid].selected_uom = action.payload.uom;
      state.fieldCalculations[action.payload.fieldUid].selected_uom_type = action.payload.uomType;

      const compValues = getFieldCompDesignValuesAndErrors(action.payload.fieldUid, state);
      state.fieldCompDesignValues[action.payload.fieldUid] = compValues.fieldCompDesignValues;
      state.fieldErrors[action.payload.fieldUid] = compValues.fieldErrors;

      state.fieldCalculationTotals[action.payload.fieldUid] = getFieldCost(action.payload.fieldUid, state, state.detailedEstimateTotals);
      state.totalDirectCost = getTotalDirectCost(state);
      const newIndirectCosts = getTotalIndirectCosts(state);
      state.indirectCostTotals = newIndirectCosts.indirectCosts;
      state.totalIndirectCost = newIndirectCosts.totalIndirectCost;
    },
    setFieldCalculationUnitCost: (state, action) => {
      if (!state.fieldCalculations[action.payload.fieldUid].unitCost) {
        state.fieldCalculations[action.payload.fieldUid].unitCost = {};
      }
      state.fieldCalculations[action.payload.fieldUid].unitCost[action.payload.key] = action.payload.value;

      if (action.payload.recalculate) {
        state.fieldCalculationTotals[action.payload.fieldUid] = getFieldCost(action.payload.fieldUid, state, state.detailedEstimateTotals);
        state.totalDirectCost = getTotalDirectCost(state);
        const newIndirectCosts = getTotalIndirectCosts(state);
        state.indirectCostTotals = newIndirectCosts.indirectCosts;
        state.totalIndirectCost = newIndirectCosts.totalIndirectCost;
      }
    },
    setFieldCalculationAdjustments: (state, action) => {
      if (!state.fieldCalculations[action.payload.fieldUid].adjustments) {
        state.fieldCalculations[action.payload.fieldUid].adjustments = {};
      }
      state.fieldCalculations[action.payload.fieldUid].adjustments.premiumPercentage = action.payload.premiumPercentage;
      state.fieldCalculations[action.payload.fieldUid].adjustments.percentOfProject = action.payload.percentOfProject;

      if (action.payload.recalculate) {
        state.fieldCalculationTotals[action.payload.fieldUid] = getFieldCost(action.payload.fieldUid, state, state.detailedEstimateTotals);
        state.totalDirectCost = getTotalDirectCost(state);
        const newIndirectCosts = getTotalIndirectCosts(state);
        state.indirectCostTotals = newIndirectCosts.indirectCosts;
        state.totalIndirectCost = newIndirectCosts.totalIndirectCost;
      }
    },
    setBucketCalculations: (state, action) => {
      state.bucketCalculations = action.payload;
    },
    setBucketCalculationUom: (state, action) => {
      state.bucketCalculations[action.payload.bucketUid].selected_uom = action.payload.uom;
      state.bucketCalculations[action.payload.bucketUid].selected_uom_type = action.payload.uomType;
      const compValues = getBucketCompDesignValues(action.payload.bucketUid, state);
      state.bucketCompDesignValues[action.payload.bucketUid] = compValues;
    },
    setBucketCalculationComp: (state, action) => {
      if (!state.bucketCalculations[action.payload.bucketUid].comps) {
        state.bucketCalculations[action.payload.bucketUid].comps = {};
      }
      if (!state.bucketCalculations[action.payload.bucketUid].comps[action.payload.ruid]) {
        state.bucketCalculations[action.payload.bucketUid].comps[action.payload.ruid] = {};
      }
      state.bucketCalculations[action.payload.bucketUid].comps[action.payload.ruid][action.payload.key] = action.payload.value;

      if (action.payload.recalculate) {
        const fieldUids = state.fields.buckets[action.payload.bucketUid];
        fieldUids.forEach((fieldUid) => {
          state.fieldCalculationTotals[fieldUid] = getFieldCost(fieldUid, state, state.detailedEstimateTotals);
        });
        state.totalDirectCost = getTotalDirectCost(state);
        const newIndirectCosts = getTotalIndirectCosts(state);
        state.indirectCostTotals = newIndirectCosts.indirectCosts;
        state.totalIndirectCost = newIndirectCosts.totalIndirectCost;
      }
    },
    setBucketGroupCalculations: (state, action) => {
      state.bucketGroupCalculations = action.payload;
    },
    setBucketGroupCalculationUom: (state, action) => {
      state.bucketGroupCalculations[action.payload.group].selected_uom = action.payload.uom;
      state.bucketGroupCalculations[action.payload.group].selected_uom_type = action.payload.uomType;
    },
    setFieldGroupCalculations: (state, action) => {
      state.fieldGroupCalculations = action.payload;
    },
    setFieldGroupCalculationUom: (state, action) => {
      state.fieldGroupCalculations[action.payload.group].selected_uom = action.payload.uom;
      state.fieldGroupCalculations[action.payload.group].selected_uom_type = action.payload.uomType;
    },
    setAllCalculations: (state, action) => {
      state.fieldCalculations = action.payload.fieldCalculations;
      state.bucketCalculations = action.payload.bucketCalculations;
      state.fieldGroupCalculations = action.payload.fieldGroupCalculations;
      state.bucketGroupCalculations = action.payload.bucketGroupCalculations;
    },
    setTotalCostUom: (state, action) => {
      state.config.data.totalCostUOM = action.payload.uom;
      state.config.data.totalCostUOMType = action.payload.uomType;
    },
    setTotalDirectCost: (state, action) => {
      state.totalDirectCost = action.payload;
    },
    setTotalIndirectCost: (state, action) => {
      state.totalIndirectCost = action.payload;
    },
    setDetailedEstimates: (state, action) => {
      state.detailedEstimates = action.payload;
    },
    setDetailedEstimate: (state, action) => {
      state.detailedEstimates[action.payload.fieldUid][action.payload.key] = action.payload.value;

      if (action.payload.recalculate) {
        state.fieldCalculationTotals[action.payload.fieldUid] = getFieldCost(action.payload.fieldUid, state, state.detailedEstimateTotals);
        state.totalDirectCost = getTotalDirectCost(state);
        const newIndirectCosts = getTotalIndirectCosts(state);
        state.indirectCostTotals = newIndirectCosts.indirectCosts;
        state.totalIndirectCost = newIndirectCosts.totalIndirectCost;
      }
    },
    addDetailedEstimate: (state, action) => {
      const fieldCalculation = state.fieldCalculations[action.payload.fieldUid];
      const fieldCalculationTotal = state.fieldCalculationTotals[action.payload.fieldUid];
      const newDetailedEstimate = detailedEstimateTemplate(fieldCalculation.selected_uom, fieldCalculation.selected_uom_type, fieldCalculation.quantity.value);
      state.detailedEstimates[action.payload.fieldUid] = newDetailedEstimate;
      state.detailedEstimateTotals[newDetailedEstimate.sortOrder[0]] = { total: 0, unit: 0, quantity: fieldCalculationTotal.quantity };
    },
    deleteDetailedEstimate: (state, action) => {
      delete state.detailedEstimates[action.payload.fieldUid];
      state.fieldCalculationTotals[action.payload.fieldUid] = getFieldCost(action.payload.fieldUid, state, state.detailedEstimateTotals);
      state.totalDirectCost = getTotalDirectCost(state);
      const newIndirectCosts = getTotalIndirectCosts(state);
      state.indirectCostTotals = newIndirectCosts.indirectCosts;
      state.totalIndirectCost = newIndirectCosts.totalIndirectCost;
    },
    addDetailedEstimateSubField: (state, action) => {
      const fieldCalculation = state.fieldCalculations[action.payload.fieldUid];
      const fieldCalculationTotal = state.fieldCalculationTotals[action.payload.fieldUid];
      const newSubField = detailedEstimateSubFieldTemplate(fieldCalculation.selected_uom, fieldCalculation.selected_uom_type, fieldCalculationTotal.quantity);

      state.detailedEstimates[action.payload.fieldUid].subFields[newSubField.uuid] = newSubField;
      state.detailedEstimates[action.payload.fieldUid].sortOrder.splice(action.payload.index, 0, newSubField.uuid);
      state.detailedEstimateTotals[newSubField.uuid] = { total: 0, unit: 0, quantity: fieldCalculationTotal.quantity };

      state.fieldCalculationTotals[action.payload.fieldUid] = getFieldCost(action.payload.fieldUid, state, state.detailedEstimateTotals);
      state.totalDirectCost = getTotalDirectCost(state);
      const newIndirectCosts = getTotalIndirectCosts(state);
      state.indirectCostTotals = newIndirectCosts.indirectCosts;
      state.totalIndirectCost = newIndirectCosts.totalIndirectCost;
    },
    deleteDetailedEstimateSubField: (state, action) => {
      state.detailedEstimates[action.payload.fieldUid].sortOrder = state.detailedEstimates[action.payload.fieldUid].sortOrder.filter(
        (subFieldUid) => subFieldUid !== action.payload.subFieldUid
      );
      delete state.detailedEstimates[action.payload.fieldUid].subFields[action.payload.subFieldUid];

      // If there are no subfields left, delete the whole detailed estimate
      if (state.detailedEstimates[action.payload.fieldUid].sortOrder.length < 1) {
        delete state.detailedEstimates[action.payload.fieldUid];
      }

      state.fieldCalculationTotals[action.payload.fieldUid] = getFieldCost(action.payload.fieldUid, state, state.detailedEstimateTotals);
      state.totalDirectCost = getTotalDirectCost(state);
      const newIndirectCosts = getTotalIndirectCosts(state);
      state.indirectCostTotals = newIndirectCosts.indirectCosts;
      state.totalIndirectCost = newIndirectCosts.totalIndirectCost;
    },
    setDetailedEstimateSubField: (state, action) => {
      state.detailedEstimates[action.payload.fieldUid].subFields[action.payload.subFieldUid][action.payload.key] = action.payload.value;
      if (action.payload.recalculate) {
        state.detailedEstimateTotals[action.payload.subFieldUid] = getDetailedEstimateSubFieldCost(action.payload.subFieldUid, action.payload.fieldUid, state);
        state.fieldCalculationTotals[action.payload.fieldUid] = getFieldCost(action.payload.fieldUid, state, state.detailedEstimateTotals);
        state.totalDirectCost = getTotalDirectCost(state);
        const newIndirectCosts = getTotalIndirectCosts(state);
        state.indirectCostTotals = newIndirectCosts.indirectCosts;
        state.totalIndirectCost = newIndirectCosts.totalIndirectCost;
      }
    },
    setDetailedEstimateSubFieldUom: (state, action) => {
      state.detailedEstimates[action.payload.fieldUid].subFields[action.payload.subFieldUid].selectedUom = action.payload.uom;
      state.detailedEstimates[action.payload.fieldUid].subFields[action.payload.subFieldUid].selectedUomType = action.payload.uomType;
      state.detailedEstimateTotals[action.payload.subFieldUid] = getDetailedEstimateSubFieldCost(action.payload.subFieldUid, action.payload.fieldUid, state);
      state.fieldCalculationTotals[action.payload.fieldUid] = getFieldCost(action.payload.fieldUid, state, state.detailedEstimateTotals);
      state.totalDirectCost = getTotalDirectCost(state);
      const newIndirectCosts = getTotalIndirectCosts(state);
      state.indirectCostTotals = newIndirectCosts.indirectCosts;
      state.totalIndirectCost = newIndirectCosts.totalIndirectCost;
    },
    setCompSelectedEstimateTemplate: (state, action) => {
      state.config.data.compSetting.root.selectedEstimateTemplate = action.payload.templateUid;

      // If its not 16 division, make sure all market comps are deleted
      if (action.payload.templateUid !== '2e051d1319f7465893ebecc52516bdcd') {
        const currentCompUids = Object.keys({ ...state.config.data.compSetting.root.comps });
        for (const compUid of currentCompUids) {
          const comp = { ...state.config.data.compSetting.root.comps[compUid] };
          if (comp.comp_category === 'market_data') {
            delete state.config.data.compSetting.root.comps[compUid];
          }
        }
      }

      // save the estimate overview config on the estimate
      if (action.payload.estimateProgrammingConfig) {
        state.config.data.programming.estimateProgrammingConfig = action.payload.estimateProgrammingConfig;
      } else {
        state.config.data.programming.estimateProgrammingConfig = [];
      }
      // save the estimate overview config on the estimate
      if (action.payload.estimateOverviewConfig) {
        state.config.data.overview = action.payload.estimateOverviewConfig;
      } else {
        state.config.data.overview = [];
      }

      if (action.payload.totalCostUOM) {
        state.config.data.totalCostUOM = action.payload.totalCostUOM;
        state.config.data.totalCostUOMType = 'custom_programming_field';
      }

      // We can clear away all custom design fields when the template is switch
      state.config.data.programming.customDesignFields = {};
      state.fieldCalculations = {};
      state.bucketCalculations = {};
      state.bucketGroupCalculations = {};
      state.fieldGroupCalculations = {};
      state.fieldCalculationTotals = {};
      state.detailedEstimates = {};
      state.detailedEstimateTotals = {};
      state.fields = initialState.fields;
      state.buckets = initialState.buckets;
      state.estimateCompsDesignFields = initialState.estimateCompsDesignFields;
    },
    deleteComp: (state, action) => {
      delete state.config.data.compSetting.root.comps[action.payload.compUid];
    },
    setComp: (state, action) => {
      state.config.data.compSetting.root.comps[action.payload.compUid][action.payload.key] = action.payload.value;
    },
    setCompanyCompProject: (state, action) => {
      state.config.data.compSetting.root.comps[action.payload.compUid].puid = action.payload.projectUid;
      state.config.data.compSetting.root.comps[action.payload.compUid].ruid = action.payload.recordUid;
      state.config.data.compSetting.root.comps[action.payload.compUid].projectMsaPremiumPercentage = action.payload.projectMsaPremiumPercentage;
      state.config.data.compSetting.root.comps[action.payload.compUid].msaPremiumPercentage = action.payload.msaPremiumPercentage;
      state.config.data.compSetting.root.comps[action.payload.compUid].isMsaPremium = action.payload.isMsaPremium;
    },
    addCompanyComp: (state, action) => {
      state.config.data.compSetting.root.comps[uuid()] = {
        comp_category: 'company_data',
        puid: action.payload.projectUid,
        ruid: action.payload.recordUid,
        isPremium: false,
        premiumPercentage: '',
        msaPremiumPercentage: action.payload.msaPremiumPercentage,
        isMsaPremium: action.payload.isMsaPremium,
        projectMsaPremiumPercentage: action.payload.projectMsaPremiumPercentage
      };
    },
    addMarketComp: (state) => {
      state.config.data.compSetting.root.comps[uuid()] = {
        comp_category: 'market_data',
        ruid: '00000000000000000000000000000000',
        buyout_date: moment().format('MM/DD/YYYY')
      };
    },
    setMarketCompFilters: (state, action) => {
      state.config.data.compSetting.root.comps[action.payload.compUid] = {
        ...state.config.data.compSetting.root.comps[action.payload.compUid],
        ...action.payload.filters
      };
    },
    setSelectedRecCostField: (state, action) => {
      if (!state.selectedRecCostFields[action.payload.fieldUid]) {
        state.selectedRecCostFields[action.payload.fieldUid] = {};
      }
      if (!state.selectedRecCostFields[action.payload.fieldUid][action.payload.recordUid]) {
        state.selectedRecCostFields[action.payload.fieldUid][action.payload.recordUid] = {};
      }
      state.selectedRecCostFields[action.payload.fieldUid][action.payload.recordUid][action.payload.key] = action.payload.value;

      if (action.payload.recalculate) {
        state.fieldCalculationTotals[action.payload.fieldUid] = getFieldCost(action.payload.fieldUid, state, state.detailedEstimateTotals);
        state.totalDirectCost = getTotalDirectCost(state);
        const newIndirectCosts = getTotalIndirectCosts(state);
        state.indirectCostTotals = newIndirectCosts.indirectCosts;
        state.totalIndirectCost = newIndirectCosts.totalIndirectCost;
      }
    },
    setCostSettingEscalation: (state, action) => {
      state.config.data.costSetting.root.escalationPerYear[action.payload.year] = action.payload.value;
    },
    addIndirectCost: (state) => {
      const prevIndirectCostUid = state.config.data.costSetting.root.indirectCostSortOrder[state.config.data.costSetting.root.indirectCostSortOrder.length - 1];
      const prevIndirectCostCost = state.config.data.costSetting.root.indirectCosts[prevIndirectCostUid].code;
      const minor = Number(prevIndirectCostCost.split('.')[1]) + 1;

      const newUid = uuid();
      state.config.data.costSetting.root.indirectCosts[newUid] = indirectCostTemplate(`99.${minor}`);
      state.config.data.costSetting.root.indirectCostSortOrder.push(newUid);
    },
    setIndirectCost: (state, action) => {
      state.config.data.costSetting.root.indirectCosts[action.payload.indirectCostUid][action.payload.key] = action.payload.value;
    },
    recalculateIndirectCosts: (state) => {
      const indirectCosts = state.config.data.costSetting.root.indirectCosts;
      const indirectCostUids = Object.keys(indirectCosts);
      let totalIndirectCost = 0;

      indirectCostUids.forEach((indirectCostUid) => {
        const cost = getIndirectCost(indirectCostUid, state.indirectCostTotals, state);
        state.indirectCostTotals[indirectCostUid] = cost;
        totalIndirectCost += cost;
      });

      state.totalIndirectCost = totalIndirectCost;
    },
    deleteIndirectCost: (state, action) => {
      state.config.data.costSetting.root.indirectCostSortOrder = state.config.data.costSetting.root.indirectCostSortOrder.filter(
        (indirectCostUid) => indirectCostUid !== action.payload.indirectCostUid
      );
      delete state.config.data.costSetting.root.indirectCosts[action.payload.indirectCostUid];
    },
    setFieldOverridesEscalation: (state, action) => {
      state.config.data.costSetting.root.fieldOverridesEscalation[action.payload.key] = action.payload.value;
    },
    recalculateFieldOverridesEscalation: (state) => {
      if (state.config.data.costSetting.root.fieldOverridesEscalation?.enabled) {
        const fromDate = new Date(state.config.data.costSetting.root.fieldOverridesEscalation.date || moment().format('MM/DD/YYYY'));
        const toDate = new Date(state.config.data.generalInformation.root.date || moment().format('MM/DD/YYYY'));
        const escalationRates = { ...state.config.data.costSetting.root.escalationPerYear };
        const newRate = calculateEffectiveEscalation(escalationRates, fromDate, toDate);

        state.config.data.costSetting.root.fieldOverridesEscalation.calculatedEscalationRate = newRate;
      }
    },
    setManualItem: (state, action) => {
      state.manualItems[action.payload.key] = action.payload.value;
    },
    setGeneralInformation: (state, action) => {
      state.config.data.generalInformation.root[action.payload.key] = action.payload.value;
    },
    setReportConfig: (state, action) => {
      state.config.data.reportConfig = action.payload;
    },
    setProForma: (state, action) => {
      state.config.data.proFormaSetting.root[action.payload.key] = action.payload.value;
    },
    setProFormaRent: (state, action) => {
      if (!state.config.data.proFormaSetting.root[action.payload.unitType]) {
        state.config.data.proFormaSetting.root[action.payload.unitType] = {};
      }
      state.config.data.proFormaSetting.root[action.payload.unitType][action.payload.index] = action.payload.value;
    },
    deleteCustomDesignField: (state, action) => {
      delete state.config.data.programming.customDesignFields[action.payload.customDesignFieldUid];

      // We need to also change any uoms that have been set to this custom design field
      // We need to do this for fieldCalculations, fieldGroupCalculations, bucketCalculations, bucketGroupCalculations and detailed estimates
      const fieldCalculations = { ...state.fieldCalculations };
      Object.keys(fieldCalculations).forEach((fieldUid) => {
        const fieldCalculation = fieldCalculations[fieldUid];
        if (fieldCalculation.selected_uom_type === 'custom_design_field' && fieldCalculation.selected_uom === action.payload.customDesignFieldUid) {
          state.fieldCalculations[fieldUid].selected_uom_type = 'standard';
          state.fieldCalculations[fieldUid].selected_uom = designFields.ConstructionDuration;
        }
      });

      const fieldGroupCalculations = { ...state.fieldGroupCalculations };
      Object.keys(fieldGroupCalculations).forEach((fieldUid) => {
        const fieldGroupCalculation = fieldGroupCalculations[fieldUid];
        if (fieldGroupCalculation.selected_uom_type === 'custom_design_field' && fieldGroupCalculation.selected_uom === action.payload.customDesignFieldUid) {
          state.fieldGroupCalculations[fieldUid].selected_uom_type = 'standard';
          state.fieldGroupCalculations[fieldUid].selected_uom = designFields.ConstructionDuration;
        }
      });

      const bucketCalculations = { ...state.bucketCalculations };
      Object.keys(bucketCalculations).forEach((fieldUid) => {
        const bucketCalculation = bucketCalculations[fieldUid];
        if (bucketCalculation.selected_uom_type === 'custom_design_field' && bucketCalculation.selected_uom === action.payload.customDesignFieldUid) {
          state.bucketCalculations[fieldUid].selected_uom_type = 'standard';
          state.bucketCalculations[fieldUid].selected_uom = designFields.ConstructionDuration;
        }
      });

      const bucketGroupCalculations = { ...state.bucketGroupCalculations };
      Object.keys(bucketGroupCalculations).forEach((fieldUid) => {
        const bucketGroupCalculation = bucketGroupCalculations[fieldUid];
        if (bucketGroupCalculation.selected_uom_type === 'custom_design_field' && bucketGroupCalculation.selected_uom === action.payload.customDesignFieldUid) {
          state.bucketGroupCalculations[fieldUid].selected_uom_type = 'standard';
          state.bucketGroupCalculations[fieldUid].selected_uom = designFields.ConstructionDuration;
        }
      });

      const detailedEstimates = { ...state.detailedEstimates };
      Object.keys(detailedEstimates).forEach((fieldUid) => {
        const detailedEstimate = detailedEstimates[fieldUid];
        const subFields = { ...detailedEstimate.subFields };
        Object.keys(subFields).forEach((subFieldUid) => {
          const subField = detailedEstimate.subFields[subFieldUid];
          if (subField.selectedUomType === 'custom_design_field' && subField.selectedUom === action.payload.customDesignFieldUid) {
            state.detailedEstimates[fieldUid].subFields[subFieldUid].selectedUomType = 'standard';
            state.detailedEstimates[fieldUid].subFields[subFieldUid].selectedUom = designFields.ConstructionDuration;
          }
        });
      });
    },
    addCustomDesignField: (state, action) => {
      state.config.data.programming.customDesignFields[action.payload.field.uid] = action.payload.field;
    },
    // For the old multifamily configs
    toggleProgrammingConfigUnitMix: (state, action) => {
      if (!state.config.data.programming['Mixed-Use Multifamily']) {
        state.config.data.programming['Mixed-Use Multifamily'] = {};
      }
      if (!state.config.data.programming['Mixed-Use Multifamily'].unitMix) {
        state.config.data.programming['Mixed-Use Multifamily'].unitMix = {};
      }
      if (action.payload.isActive) {
        state.config.data.programming['Mixed-Use Multifamily'].unitMix[action.payload.configKey] = [defaultProgrammingConfig[action.payload.configKey]];
      } else {
        state.config.data.programming['Mixed-Use Multifamily'].unitMix[action.payload.configKey] = [];
      }
    },
    addProgrammingConfigUnitMix: (state, action) => {
      state.config.data.programming['Mixed-Use Multifamily'].unitMix[action.payload.configKey].push(defaultProgrammingConfig[action.payload.configKey]);
    },
    setProgrammingConfigUnitMix: (state, action) => {
      state.config.data.programming['Mixed-Use Multifamily'].unitMix[action.payload.configKey][action.payload.index][action.payload.key] = action.payload.value;
    },
    deleteProgrammingConfigUnitMix: (state, action) => {
      state.config.data.programming['Mixed-Use Multifamily'].unitMix[action.payload.configKey] = state.config.data.programming['Mixed-Use Multifamily'].unitMix[
        action.payload.configKey
      ].filter((_, i) => i !== action.payload.index);
    },
    // For the new custom configs
    toggleEstimateProgrammingConfigGroup: (state, action) => {
      state.config.data.programming.estimateProgrammingConfig[action.payload.groupUid].is_enabled = action.payload.isActive;
    },
    toggleEstimateProgrammingConfigItemValue: (state, action) => {
      state.config.data.programming.estimateProgrammingConfig[action.payload.groupUid].estimate_programming_config_items[action.payload.itemUid].value = action.payload.value;
    },
    setCompareConfig: (state, action) => {
      state.config.data.compareConfig.root = action.payload;
    }
  },
  extraReducers: (builder) => {
    // SET CUSTOM DESIGN FIELD VALUE
    builder.addCase(setCustomDesignField.pending, (state, action) => {
      state.config.data.programming.customDesignFields[action.meta.arg.customDesignFieldUid].value = action.meta.arg.value;
    });
    builder.addCase(setCustomDesignField.fulfilled, (state, action) => {
      state.fieldCalculationTotals = action.payload.fieldCalculationTotals;
      state.detailedEstimateTotals = action.payload.detailedEstimateTotals;
      state.totalDirectCost = action.payload.totalDirectCost;
    });
    // SET ESTIMATE DESIGN VALUES
    builder.addCase(setEstimateDesignValues.pending, (state, action) => {
      state.estimateDesignValues = {
        ...state.estimateDesignValues,
        ...action.meta.arg.estimateDesignValues
      };
    });
    builder.addCase(setEstimateDesignValues.fulfilled, (state, action) => {
      state.fieldCalculationTotals = action.payload.fieldCalculationTotals;
      state.detailedEstimateTotals = action.payload.detailedEstimateTotals;
      state.totalDirectCost = action.payload.totalDirectCost;
    });
    ////  GET RECORDS ////
    // PENDING
    builder.addCase(getRecords.pending, (state) => {
      state.records.getApiStatus = API_STATUS_ENUM.PENDING;
    });
    // REJECTED
    builder.addCase(getRecords.rejected, (state) => {
      state.records.getApiStatus = API_STATUS_ENUM.ERROR;
    });
    // SUCCESS
    builder.addCase(getRecords.fulfilled, (state, action) => {
      state.records.data = action.payload;
      state.records.getApiStatus = API_STATUS_ENUM.SUCCESS;
    });

    //// PUT RECORD ////
    // PENDING
    builder.addCase(putRecord.pending, (state) => {
      state.records.putApiStatus = API_STATUS_ENUM.PENDING;
    });
    // REJECTED
    builder.addCase(putRecord.rejected, (state) => {
      state.records.putApiStatus = API_STATUS_ENUM.ERROR;
    });
    // SUCCESS
    builder.addCase(putRecord.fulfilled, (state) => {
      state.records.putApiStatus = API_STATUS_ENUM.SUCCESS;
    });

    //// DELETE RECORD ////
    // PENDING
    builder.addCase(deleteRecord.pending, (state) => {
      state.deleteEstimate.deleteApiStatus = API_STATUS_ENUM.PENDING;
    });
    // REJECTED
    builder.addCase(deleteRecord.rejected, (state) => {
      state.deleteEstimate.deleteApiStatus = API_STATUS_ENUM.ERROR;
    });
    // SUCCESS
    builder.addCase(deleteRecord.fulfilled, (state) => {
      state.deleteEstimate.isOpen = false;
      state.deleteEstimate.deleteApiStatus = API_STATUS_ENUM.SUCCESS;
    });

    //// GET ESTIMATE COMPS ////
    // PENDING
    builder.addCase(getEstimateComps.pending, (state) => {
      state.estimateComps.getApiStatus = API_STATUS_ENUM.PENDING;
    });
    // REJECTED
    builder.addCase(getEstimateComps.rejected, (state) => {
      state.estimateComps.getApiStatus = API_STATUS_ENUM.ERROR;
    });
    // SUCCESS
    builder.addCase(getEstimateComps.fulfilled, (state, action) => {
      state.estimateComps.getApiStatus = API_STATUS_ENUM.SUCCESS;
      state.estimateComps.data = action.payload;
    });

    //// GET ESTIMATE COMPS DESIGN FIELDS ////
    // PENDING
    builder.addCase(getEstimateCompsDesignFields.pending, (state) => {
      state.estimateCompsDesignFields.getApiStatus = API_STATUS_ENUM.PENDING;
    });
    // REJECTED
    builder.addCase(getEstimateCompsDesignFields.rejected, (state) => {
      state.estimateCompsDesignFields.getApiStatus = API_STATUS_ENUM.ERROR;
    });
    // SUCCESS
    builder.addCase(getEstimateCompsDesignFields.fulfilled, (state, action) => {
      state.estimateCompsDesignFields.getApiStatus = API_STATUS_ENUM.SUCCESS;
      state.estimateCompsDesignFields.data = action.payload;
    });

    //// GET DESIGN RECORD FIELDS ////
    // PENDING
    builder.addCase(getDesignRecordFields.pending, (state) => {
      state.designRecordFields.getApiStatus = API_STATUS_ENUM.PENDING;
    });
    // REJECTED
    builder.addCase(getDesignRecordFields.rejected, (state) => {
      state.designRecordFields.getApiStatus = API_STATUS_ENUM.ERROR;
    });
    // SUCCESS
    builder.addCase(getDesignRecordFields.fulfilled, (state, action) => {
      state.designRecordFields.getApiStatus = API_STATUS_ENUM.SUCCESS;
      state.designRecordFields.data = action.payload.buckets;
      state.designRecordFields.fields = action.payload.fields;
    });

    //// GET BUCKETS ////
    // PENDING
    builder.addCase(getBuckets.pending, (state) => {
      state.buckets.getApiStatus = API_STATUS_ENUM.PENDING;
    });
    // REJECTED
    builder.addCase(getBuckets.rejected, (state) => {
      state.buckets.getApiStatus = API_STATUS_ENUM.ERROR;
    });
    // SUCCESS
    builder.addCase(getBuckets.fulfilled, (state, action) => {
      state.buckets.data = action.payload.buckets;
      state.buckets.groups = action.payload.groups;
      state.buckets.getApiStatus = API_STATUS_ENUM.SUCCESS;
    });

    //// GET FIELDS ////
    // PENDING
    builder.addCase(getFields.pending, (state) => {
      state.fields.getApiStatus = API_STATUS_ENUM.PENDING;
    });
    // REJECTED
    builder.addCase(getFields.rejected, (state) => {
      state.fields.getApiStatus = API_STATUS_ENUM.ERROR;
    });
    // SUCCESS
    builder.addCase(getFields.fulfilled, (state, action) => {
      state.fields.data = action.payload.fields;
      state.fields.groups = action.payload.groups;
      state.fields.buckets = action.payload.buckets;
      state.fields.getApiStatus = API_STATUS_ENUM.SUCCESS;
    });

    ////  GET COMP RECORD TOTAL COST ////
    // PENDING
    builder.addCase(getCompRecordTotalCost.pending, (state, action) => {
      if (!state.compRecordsTotalCost[action.meta.arg.recordUid]) {
        state.compRecordsTotalCost[action.meta.arg.recordUid] = {
          data: {},
          getApiStatus: API_STATUS_ENUM.PENDING
        };
      }
      state.compRecordsTotalCost[action.meta.arg.recordUid].getApiStatus = API_STATUS_ENUM.PENDING;
    });
    // REJECTED
    builder.addCase(getCompRecordTotalCost.rejected, (state, action) => {
      if (!state.compRecordsTotalCost[action.meta.arg.recordUid]) {
        state.compRecordsTotalCost[action.meta.arg.recordUid] = {
          data: {},
          getApiStatus: API_STATUS_ENUM.ERROR
        };
      }
      state.compRecordsTotalCost[action.meta.arg.recordUid].getApiStatus = API_STATUS_ENUM.ERROR;
    });
    // SUCCESS
    builder.addCase(getCompRecordTotalCost.fulfilled, (state, action) => {
      state.compRecordsTotalCost[action.meta.arg.recordUid].data = action.payload;
      state.compRecordsTotalCost[action.meta.arg.recordUid].getApiStatus = API_STATUS_ENUM.SUCCESS;
    });

    ////  GET MARKET COMP TOTAL COST ////
    // PENDING
    builder.addCase(getMarketCompTotalCost.pending, (state, action) => {
      if (!state.marketCompTotalCost[action.meta.arg.selectedUOM]) {
        state.marketCompTotalCost[action.meta.arg.selectedUOM] = {
          data: {},
          getApiStatus: API_STATUS_ENUM.PENDING
        };
      }
      state.marketCompTotalCost[action.meta.arg.selectedUOM].getApiStatus = API_STATUS_ENUM.PENDING;
    });
    // REJECTED
    builder.addCase(getMarketCompTotalCost.rejected, (state, action) => {
      if (!state.marketCompTotalCost[action.meta.arg.selectedUOM]) {
        state.marketCompTotalCost[action.meta.arg.selectedUOM] = {
          data: {},
          getApiStatus: API_STATUS_ENUM.ERROR
        };
      }
      state.marketCompTotalCost[action.meta.arg.selectedUOM].getApiStatus = API_STATUS_ENUM.ERROR;
    });
    // SUCCESS
    builder.addCase(getMarketCompTotalCost.fulfilled, (state, action) => {
      state.marketCompTotalCost[action.meta.arg.selectedUOM].data = action.payload;
      state.marketCompTotalCost[action.meta.arg.selectedUOM].getApiStatus = API_STATUS_ENUM.SUCCESS;
    });

    //// GET CONFIG ////
    // PENDING
    builder.addCase(getConfig.pending, (state) => {
      state.config.getApiStatus = API_STATUS_ENUM.PENDING;
    });
    // REJECTED
    builder.addCase(getConfig.rejected, (state) => {
      state.config.getApiStatus = API_STATUS_ENUM.ERROR;
    });
    // SUCCESS
    builder.addCase(getConfig.fulfilled, (state, action) => {
      state.fieldCalculations = action.payload.fieldCalculations;
      state.bucketCalculations = action.payload.bucketCalculations;
      state.fieldGroupCalculations = action.payload.fieldGroupCalculations;
      state.bucketGroupCalculations = action.payload.bucketGroupCalculations;
      state.detailedEstimates = action.payload.detailedEstimates;
      state.manualItems = action.payload.manualItems;
      state.selectedRecCostFields = action.payload.selectedRecCostFields;
      state.config.data = action.payload.config;
      state.consolidatedRecordsConfigs = action.payload.consolidatedRecordsConfigs;
      state.isConsolidated = action.payload.isConsolidated;
      state.isConsolidatedTemplatesValid = action.payload.isConsolidatedTemplatesValid;
      state.templates = action.payload.templates;
      state.estimateDesignValues = action.payload.estimateDesignValues;
      state.consolidatedSoftCosts = action.payload.consolidatedSoftCosts;
      state.config.getApiStatus = API_STATUS_ENUM.SUCCESS;
    });

    // SAVE CONFIG //
    // PENDING
    builder.addCase(saveConfig.pending, (state, action) => {
      state.savingConfigs[action.meta.arg.configType] = true;
    });
    // REJECTED
    builder.addCase(saveConfig.rejected, (state, action) => {
      state.savingConfigs[action.meta.arg.configType] = false;
    });
    // SUCCESS
    builder.addCase(saveConfig.fulfilled, (state, action) => {
      state.savingConfigs[action.meta.arg.configType] = false;
    });

    // SAVE BUCKETS AND FIELDS
    builder.addCase(saveBucketsAndFields.pending, (state) => {
      state.isSaving = true;
      state.isSavingError = false;
    });
    builder.addCase(saveBucketsAndFields.rejected, (state) => {
      state.isSaving = false;
      state.isSavingError = true;
    });
    builder.addCase(saveBucketsAndFields.fulfilled, (state) => {
      state.isSaving = false;
      state.isSavingError = false;
    });

    // INITIALIZE ALL CALCULATIONS
    builder.addCase(initializeAllCalculations.fulfilled, (state, action) => {
      state.fieldCalculations = action.payload.fieldCalculations;
      state.fieldCalculationTotals = action.payload.fieldCalculationTotals;
      state.detailedEstimateTotals = action.payload.detailedEstimateTotals;
      state.bucketCalculations = action.payload.bucketCalculations;
      state.fieldGroupCalculations = action.payload.fieldGroupCalculations;
      state.bucketGroupCalculations = action.payload.bucketGroupCalculations;
      state.config.data.programming.customDesignFields = action.payload.customDesignFields;

      if (!state.config.data.totalCostUOM) {
        state.config.data.totalCostUOM = action.payload.defaultTotalCostUom;
        state.config.data.totalCostUOMType = action.payload.defaultTotalCostUomType;
      }
    });

    // CALCULATE ALL CALCULATIONS
    builder.addCase(calculateAllCalculations.fulfilled, (state, action) => {
      state.fieldCalculationTotals = action.payload.fieldCalculationTotals;
      state.detailedEstimateTotals = action.payload.detailedEstimateTotals;
      state.totalDirectCost = action.payload.totalDirectCost;
    });

    // CALCULATE ALL INDIRECT COSTS
    builder.addCase(calculateAllIndirectCosts.fulfilled, (state, action) => {
      state.indirectCostTotals = action.payload.indirectCostTotals;
      state.totalIndirectCost = action.payload.totalIndirectCost;
    });

    // SET COMP DESIGN VALUES
    builder.addCase(setCompDesignValues.fulfilled, (state, action) => {
      state.fieldCompDesignValues = action.payload.fieldCompDesignValues;
      state.bucketCompDesignValues = action.payload.bucketCompDesignValues;
      state.fieldErrors = action.payload.fieldErrors;
    });
  }
});
export const {
  resetEstimate,
  setPrimaryStep,
  setActiveTab,
  setIsAdjustmentColumnVisible,
  setIsNotesColumnVisible,
  setIsOverviewMinimized,
  setIsDeleteEstimateOpen,
  setIsRenameEstimateOpen,
  setIsFullScreen,
  setIsSaving,
  setEffectiveEscalation,
  updateRecord,
  setFieldCalculation,
  setFieldCalculationUnitCost,
  setFieldCalculationAdjustments,
  setFieldCalculationUom,
  recalculateFieldCalculation,
  setBucketCalculationUom,
  setBucketCalculationComp,
  setBucketCalculations,
  setBucketGroupCalculations,
  setBucketGroupCalculationUom,
  setFieldGroupCalculations,
  setFieldGroupCalculationUom,
  setAllCalculations,
  setTotalCostUom,
  setTotalDirectCost,
  setTotalIndirectCost,
  recalculateIndirectCosts,
  addDetailedEstimate,
  deleteDetailedEstimate,
  setDetailedEstimates,
  setDetailedEstimate,
  setDetailedEstimateSubField,
  setDetailedEstimateSubFieldUom,
  addDetailedEstimateSubField,
  deleteDetailedEstimateSubField,
  setCompSelectedEstimateTemplate,
  deleteComp,
  setComp,
  addCompanyComp,
  addMarketComp,
  setCompanyCompProject,
  setMarketCompFilters,
  setSelectedRecCostField,
  setCostSettingEscalation,
  setFieldOverridesEscalation,
  recalculateFieldOverridesEscalation,
  addIndirectCost,
  setIndirectCost,
  deleteIndirectCost,
  setManualItem,
  setGeneralInformation,
  setReportConfig,
  setProForma,
  setProFormaRent,
  deleteCustomDesignField,
  addCustomDesignField,
  toggleProgrammingConfigUnitMix,
  addProgrammingConfigUnitMix,
  setProgrammingConfigUnitMix,
  deleteProgrammingConfigUnitMix,
  toggleEstimateProgrammingConfigGroup,
  toggleEstimateProgrammingConfigItemValue,
  setCompareConfig
} = estimateSlice.actions;

export default estimateSlice.reducer;
